a:-webkit-any-link {
    color: unset;
    cursor: pointer;
    text-decoration: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.w-30-xs{
  width: 30%
}
.ltr-inputgroup-button{
  height: 50px;
  border-right: none;
  border: 1px solid #e7e7e7
}
.input-group-lg .input-custome-button{
  height: 50px !important
}
.border-bottom-dotted{
  border-bottom: 1px dotted #e7e7e7
}
.min-dropdownmenu{
  max-width: 10px !important
}
.input-group-lg.ltr input{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  border-color: #232731 !important;
  border: 1px solid #232731;
  border-right: 1px solid #232731 !important;
}
.input-group-lg.ltr .dropdown-menu{
  width: 50px !important
}
.input-group-lg.ltr .btn{
  width: 60px !important;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-color: #232731 !important;
  border: 1px solid #232731 !important;
  border-right: none !important;
  border-right: none !important
}
.input-group-lg.ltr{
  height: 50px;
  direction: ltr;
}
body.dark [type="search"]::-webkit-search-cancel-button {
  position: absolute;
  left: auto;
  right: 10px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  border-radius: 50%;
  height: 5px;
  width: 5px;
  border: 1px solid var(--mint);
  padding: 3px;
  background-color: #ffffff00;
}
body.light [type="search"]::-webkit-search-cancel-button {
    position: absolute;
    left: auto;
    right: 10px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
    border-radius: 50%;
    height: 10px;
    width: 10px;
    border: 1px solid gray;
    padding: 3px;
    background-color: white;

}
.input-group .btn:hover{
  color: inherit !important
}
.input-group .btn{
  vertical-align: middle;
  align-items: center;

}
.input-group .btn:focus{
  outline: none !important;
  box-shadow: none !important
}
.symbol-row.irprice{
    min-width: 150px !important
}
.card-body{
  min-height: 400px;
}
.market-modal-header.modal-header{
  justify-content: flex-end;
  border-bottom: 1px solid #ffffff00 !important
}
.market-modal-header .modal-title{
  width: 100%;
}
body.dark .market-modal-header{
  background-color: #061621
}
body.light .market-modal-header{
  background-color: #fff
}
.market-modal-header{
  position: sticky;
  top: 0;
  z-index: 20
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
body.dark .tvlogo{
  filter: contrast(0);
}
body.dark .rewards-badge{
  padding: 5px 15px 5px 15px;
  background-color: cyan;
  color: black !important;
  border-radius: 20px
}
.reward-detail-row{
  /* display: flex; */
}
.input-group input[type='search']{
  height: 30px !important;
  border-radius: 15px;
  padding-left: 20px;
  margin: 5px 0
}
body.dark [type="search"]::-webkit-search-cancel-button {
  position: absolute;
  left: auto;
  right: 10px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  border-radius: 50%;
  height: 5px;
  width: 5px;
  border: 1px solid var(--mint);
  padding: 3px;
  background-color: #ffffff00;
}
body.light [type="search"]::-webkit-search-cancel-button {
    position: absolute;
    left: auto;
    right: 10px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
    border-radius: 50%;
    height: 10px;
    width: 10px;
    border: 1px solid gray;
    padding: 3px;
    background-color: white;

}
body.dark .rewards-badge-outline{
  padding: 5px 15px 5px 15px;
  color: inherit !important;
  border-radius: 20px;
  border: 1px solid cyan
}
.reward-detail-row{
  /* display: flex; */
}
.reward-detail-row *{
  display: inline-table;
width: -webkit-fill-available;
}
.inline-table{
  display: inline-table !important
}
body.dark .rewards-badge:hover{

  color: 000 !important;
}
.text-tiny{
  font-size: 7px !important;
  margin-top: 10px
}
body.light .rewards-badge{
  padding: 5px 15px 5px 15px;
  background-color: #ffc107 !important;
  color: black !important;
  border-radius: 20px
}
body.light .rewards-badge-outline{
  padding: 5px 15px 5px 15px;
  border: 1px solid #ffc107 !important;
  color: black !important;
  border-radius: 20px
}
body.light .rewards-badge:hover{

  color: gary !important;
}
body.dark .rw-container{
  margin-top: 30px;
  padding: 30px 20px;
  background-color: #e7e7e705;
  border: 1px solid #e7e7e760;
  border-radius: 20px
}
body.light .rw-container{
  margin-top: 30px;
  padding: 30px 20px;
  background-color: #e7e7e705;
  border: 1px solid #e7e7e7;
  border-radius: 20px
}
body.dark .rw-step-container{
  border-top: 1px solid #e7e7e730;
  margin-top: 30px;
  height: 200px;
  padding-top: 10px
}
body.light .rw-step-container{
  border-top: 1px solid indigo;
  margin-top: 30px;
  height: 200px;
  padding-top: 10px
}
.ltr{
  text-align: left;
  direction: ltr;
  align-items: flex-end;
}
body.dark .step-reward-status{
  position: absolute;
width: 3px;
background-color: #ffffff30;
height: 130px;
float: left;
left: 25;
top:65
}
.icon-row-slug{
  font-size: 9px
}
body.dark .round-btn{
  background-color: #fff;
  color: #000
}
body.dark .deposit-r-btn{
padding: 20px;
border: 2px solid #fff;
border-radius: 50%;
}
body.light .deposit-r-btn{
padding: 20px;
border: 2px solid #000;
border-radius: 50%;
}
body.dark .send-btn{
padding: 20px;
border: 2px solid #fff;
border-radius: 50%;
}
body.light .send-btn{
padding: 20px;
border: 2px solid #000;
border-radius: 50%;
}
body.dark .recieve-btn{
padding: 20px;
border: 2px solid #fff;
border-radius: 50%;
}
body.light .recieve-btn{
padding: 20px;
border: 2px solid #000;
border-radius: 50%;
}
body.dark .withdraw-btn{
padding: 20px;
border: 2px solid #fff;
border-radius: 50%;
}
body.light .withdraw-btn{
padding: 20px;
border: 2px solid #000;
border-radius: 50%;
}
body.dark .history-btn{
padding: 20px;
border: 2px solid #fff;
border-radius: 50%;
}
body.light .history-btn{
padding: 20px;
border: 2px solid #000;
border-radius: 50%;
}
.round-btn{
  border: 1px solid #fff;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  align-items: center;
  padding: 3px;
  align-items: center;
  }
body.light .step-reward-status{
  position: absolute;
width: 3px;
background-color: lightblue;
height: 130px;
float: left;
left: 25;
top:65
}
.step-counter{
position: absolute;
padding: 10px 15px ;
background-color: #ffffff30;
float: left;
right: auto;
align-items: center;
vertical-align: middle;
text-align: center;
border-radius: 50%;
left: 10;
top: 10
}
body.light .step-counter{
position: absolute;
padding: 10px 15px ;
background-color: indigo;
float: left;
color: #fff;
right: auto;
align-items: center;
vertical-align: middle;
text-align: center;
border-radius: 50%;
left: 10;
top: 10
}
body.dark .step-button{
  color: #000
}
body.dark .step-button.active{
  color: #fff;
  background-color: indigo
}
body.light .step-button{
  color: #000
}
body.light .step-button.active{
  color: #fff;
  background-color: indigo
}
.step-button{
  position: absolute;
  background-color: #e7e7e7;
  padding: 7px 35px;
  border-radius: 6px;
  margin-top: 60px;
  max-width: fit-content;
  min-width: 130px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
}
body.dark .step-reward-status.fill{
  background-color: cyan
}
body.light .step-reward-status.fill{
  background-color: indigo
}
.h-200{
  height: 200px
}
.h100{
  min-height: 100px
}
.mc-nav-item small{
  font-size: 9px !important;
  line-height: 20px;
  text-align: center;
  align-items: center;
}
.ag-row{
}
.ag-row-position-absolute {
    /* position: relative; */
}
a.bdage-market-success{
  padding: 10px 20px;
  color: #000 !important;
}
.bdage-market-success{
  min-width: 50px;
  color: green;
  background-color: cyan;
  padding: 5px 10px 5px 10px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  border-radius: 20px
}
.input-group input.swap-input {
    height: 50px;
}
.ag-header-row:last-child{
  width: fit-content !important
}
.ag-header-row:last-child .ag-header-cell-resize{
  display: none !important
}
.ag-paging-row-summary-panel span{
  margin: 0 5px 0 5px
}
.ag-paging-row-summary-panel{
  display: none;
  justify-content: space-around;
}
.ag-root-wrapper{
  border-radius: 0px !important;
}
.ag-paging-description span{
  margin: 0 5px 0 5px
}
.ag-paging-description{
  display: flex;
  justify-content: space-around;
}
.bdage-market-danger{
  min-width: 50px;
  color: red;
  background-color: pink;
  padding: 5px 10px 5px 10px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  border-radius: 20px
}
.bdage-market-primary{
  min-width: 50px;
  color: purple;
  background-color: indigo;
  padding: 5px 10px 5px 10px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  border-radius: 20px
}
input.ag-input-field-input {
  height: 36px !important
}
.ag-theme-quartz-dark *{

  font-size: 12px !important
}
.quote-icon{
  margin-left: -6px;
  margin-top: -10px !important
}
.ag-theme-quartz *{

  font-size: 12px !important
}
.mc-nav-item span{
  font-size: 9px !important;
  line-height: 20px;
  text-align: center;
  align-items: center;
}
.mc-nav-item-last small{
  font-size: 9px !important;
  line-height: 20px;
  text-align: center;
  align-items: center;
}
.mc-nav-item-last span{
  font-size: 9px !important;
  line-height: 20px;
  text-align: center;
  align-items: center;
}
.market-td{
  display: block;
  min-width: 80px;
  padding: 0 10px 0 10px;
}
td{
  min-width: 50px;
  padding: 0 10px 0 10px;
}
td *{
  width: max-content;

}
.cr-row:hover{
  scale : 1.004;
  filter: brightness(0.9);
}
body.dark .cr-row{
  background-color: #2d61ab;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 50px;
  text-align: ltr;
  align-content: center;
  vertical-align: middle;
  text-align: left;
  padding: 0 10px 0 20px

}
body.dark .cr-row-header{
  background-color: #306bbe;
  border-radius: 5px;
  margin-bottom: 5px;
  height: 50px;
  text-align: ltr;
  align-content: center;
  vertical-align: middle;
  text-align: left;
  padding: 0 10px 0 20px

}
.pagination *{
  height: 32px !important
}
body.dark .pagination *{
  color: inherit;
  background-color: inherit;
}
body.light .cr-row-header{
  background-color: #306bbe;
  border-radius: 5px;
  margin-bottom: 5px;
  height: 50px;
  text-align: ltr;
  align-content: center;
  vertical-align: middle;
  text-align: left;
  padding: 0 10px 0 20px

}
body.dark .icon-navbar:hover{

}
body.light .icon-navbar:hover{
  filter: contrast(0.5);
  scale :1.06;
  border: 1px solid purple
}
body.dark .icon-navbar *{
  color: #a4a4a4 !important

}

body.dark .icon-navbar{
  border: 1px solid #1c5050;
align-items: center;
vertical-align: middle;
display: block;
align-content: space-evenly;
align-self: inherit;
padding: 7px;
border-radius: 5px;
cursor: pointer;
margin-left: 15px;
color: #e7e7e7 !important

}
body.light .icon-navbar{
  border: 1px solid #ac7e7e60;
align-items: center;
vertical-align: middle;
display: block;
align-content: space-evenly;
align-self: inherit;
padding: 7px;
border-radius: 5px;
cursor: pointer;
margin-left: 15px;
color: #ac7e7e !important
}
body.light .cr-row{
  background-color: #9278ff;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 50px;
  text-align: ltr;
  align-content: center;
  vertical-align: middle;
  text-align: left;
  padding: 0 10px 0 20px;
  color:white
}
.cr-col-0{
  width: 310px !important
}
body.dark .cr-col-1{
  width: 60px;
}
body.dark .cr-col-2{
  width: 250px !important;
  display: block;
}
body.dark .cr-col-3{
  min-width: 200px !important;
}
body.dark .cr-col-4{
  width: 200px !important;
}
body.dark .cr-col-5{
  width: 200px !important;
}
body.dark .cr-col-6{
  width: 100px !important;
}
body.light .cr-col-1{
  width: 60px !important;
}
body.light .cr-col-2{
  width: 250px !important;
  display: block;
}
.container-fluid{
  vertical-align: middle;
  align-items: center;
}
body.light .cr-col-3{
  width: 200px !important;
}
body.light .cr-col-4{
  width: 200px !important;
}
body.light .cr-col-5{
  width: 200px !important;
}
body.light .cr-col-6{
  width: 100px !important;
}
.cryptolist-table tr{
  height: 60px !important;
  margin-bottom: 30px;
}
.bank-logo{
  width: 65px !important
}
.btn-group{
  border-radius: 10px !important
}
th{
  min-width: 50px;
  padding: 0 10px 0 10px;
}
.slide h5{
  color: #fff !important;
  margin-top: 20px;
}
.slide p{
  color: #fff !important;
  line-height: 25px
}
.footer-logo-container {
  background-image: url('./images/logo-dark.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 250px;
  height: 50px;
  display: block;
  margin: auto;
}
input {
    font-style: ;
    font-variant-ligatures: ;
    font-variant-caps: ;
    font-variant-numeric: ;
    font-variant-east-asian: ;
    font-variant-alternates: ;
    font-variant-position: ;
    font-weight: ;
    font-stretch: ;
    font-size: ;
    font-family: ;
    font-optical-sizing: ;
    font-kerning: ;
    font-feature-settings: ;
    font-variation-settings: ;
    text-rendering: auto;
    color: unset;
    letter-spacing :inherit;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    box-shadow : none;
    display: inline-block;
    text-align: unset;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    cursor: text;
    background-color: unset;
    margin: 0em;
    padding: 1px 0px;
    border-width: 1px;
    border-style: inset;
    border-color: unset;
    border-image: initial;
    padding-block: 1px;
    padding-inline: 1px;
  }
.carousel-status{
  display: none !important
}
.market-layer .btn-danger{
  height: 40px
}
.modal-dialog.sidebar .modal-content {
  background-color: #000;
  color: #fff
}
body.light .top-navbar-md{
  border-bottom: 1px solid #8080804f
}
body.dark .top-navbar-md{
  border-bottom: 1px solid #8080804f
}
body.light .b-small{
  color: #000 !important
}
body.dark .sidebar.modal-dialog {
  height: 100%;
  min-width: 270px !important;
  position: fixed;
  right: -10;
  z-index: 3;
  background-color: #000;
  padding-bottom: 100px
}
.ask-bid-full{
  height: 600px
}

.input-custome-button .d-flex span{
  margin: 0 5px 0 5px

}
.input-custome-button{
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.market-layer .btn-success{
  height: 40px
}
.alert-info {
    color: #0c5460 !important;
    background-color: #d1ecf1 !important;
    border-color: #bee5eb;
}
.market-layer .toright-collapse input.market-input {
width: 450px !important ;
margin-top: 50px !important
}
.alert-info a{
    color: #0c5460 !important;

}
.alert-info p{
    color: #0c5460 !important;

}
.table-small{
  font-size: 11px
}
 input:-internal-autofill-selected{
    appearance: menulist-button;
    background-image: none !important;
    background-color: none !important;
    color: unset;
}


  body {
      display: block;
      margin: 0;
  }
  :root {
  --orange: #ffc101;
  --white: #ffffff;
  --blue: #007bff;
  --lightblue : #7cabf8;
  --gray-1 : #d3d3d3;
  --gray-10 : darkslategray;
  --mint : #06bc8d;
  --neon : #ff4026
}
input:focus-visible{
  box-shadow: none;
  outline: none
}
.w-100{
  width: 100%
}
h6 {
    display: block;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}
.btn{
  font-family: -apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif;
}
.rtl{
  direction: rtl;
  text-align: right;
}
.btn-success {
    background-color: #15a800;
    border: 1px solid #15a800;
    color: #fff;
}
.btn-danger {
    background-color: #ff4026;
    border:1px solid #ff4026;
    color: #fff;
}
.sidebar {
  overflow-y: auto;
  overflow-x: hidden;
  overflow: auto;
}
.lh-xl{
  line-height: 46px
}
.sidebar aside {

  height: 120%
}
.r-0{
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
body.dark .xs-overlay {
	height: 90px;
  padding: 20;
  justify-content: space-between;
}
body.light .xs-overlay {
	border: 1px solid #e7e7e7;
	height: 90px;
  padding: 20;
  border-radius: 15px;
}
.market-layer-main .btn-success{
  min-height: 40px!important
}
.market-layer-main .btn-danger{
  min-height: 40px!important
}
.market-layer-main .btn-light{
  min-height: 40px
}
.quote-icon-td{
  width: 24px;
    height: 24px;
    left: -6px;
    position: relative;
    top: -5px;
}
.small-icon{
  width: 24px;
  height: 24px;

}
.loader-home {
  min-height: 500px;
  z-index: 99999
}

.loader-home h3 {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: inherit;

}
.loader-home-mini h3 {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.loader-home {
  overflow: hidden;
  min-height: 600px;
  width: 100%;
  background-color: #000518;
  top: 0;
  left: 0;
  bottom: 0;
  position: fixed;
}
.loader-home-mini {
  overflow: hidden;
  min-height: 600px;
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 0;
}

.ps-menuitem-root{
  height: max-content
}
table{
  line-height: 20px
}
.modal table{
  line-height: 48px
}

.perfectmoney-logo {
  width: 100px
}
.text-center {
    text-align: center !important;
}
.footer-links{
  display: grid;
}
.footer-links a{
  display: flex;
  line-height: 50px;
  font-size: 11px;
  align-items: center;
}
.main-logo-dark{
  height: 90px;
}
.btn-primary {
    background-color: #007bff;
    border: none ;
    color: #fff;
}
*::-webkit-scrollbar {
    width: 1px;
		height: 3px;
		background-color: #ffffff00
}
.thumb-img{
  width: 60px;
  height: 60px
}
.card-header{
  overflow-x: auto;
}

/* .menuBox::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
} */

*::-webkit-scrollbar-thumb {
  background-color: #ffffff00 !important;
  border: 1px solid #ffffff00 !important;
}
body.dark .modal-header .close{
  color: #fff !important;
  margin-right: auto;
  margin-left: 10px !important
}
body.dark .mc-header-3 div span{
  font-size: 18px !important

}
body.dark .mc-header-3 div{
  background-color: #181b26 !important;
  text-align: center !important;
  padding-left: 10px;
  padding-right: 30px;
}
.h-300{
  min-height: 300px;
  max-height: max-content;
  padding-bottom: 30px
}
body.dark .modal-header{
  border-bottom: 1px solid #00336530;
  align-items: center;
  vertical-align: middle;
  direction: inherit;
}
.market-avatar {
    border: 1px solid #28b0df;
    border-radius: 50%;
    /* margin: auto 0 0;
    padding-top: 4px; */
    width: 32px;
    align-content: center;
    align-items: center;
    display: inline-block;
}
.deposit-btn, {
    align-content: center;
    align-items: center;
    height: 32px;
    text-align: center;
    display: flex
}
.justify-content-space-between{
  justify-content: space-between;
  vertical-align: middle;
  align-items: center;
}
.justify-content-space-around{
  justify-content: flex-start;
  vertical-align: middle;
  align-items: center;
}
p {
    display: block;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}
.market-layer{
  .text-success{
    color: #06bc8d;

  }
.text-danger{
    color: #f56754;

  }
}
body.dark .b-small {
  font-size: 10px;
width: 100%;
cursor: pointer;
display: table-cell;
text-align: center;
padding: 2px 0px 0px 0px;
border: 1px solid #9292921f;
border-radius: 5px;
background-color: #13161f;
}
.scrollHider-gutter{
  display: flex;
  width: auto;
}
body.light .b-small {
  font-size: 10px;
  width: 100%;
  cursor: pointer;
  display: table-cell;
  text-align: center;
  border: 1px solid #cacaca;
  border-radius: 5px;
  background-color: #e6e6e6;
}
/* .table{
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
} */
.btn-light{
  background-color: gary !important;
}
body.light .dropdown-menu{
  border: 1px solid #e7e7e7;
  background-color: #fff

}
body.dark .dropdown-menu{
  border: 1px solid #4b4e5c;
  background: #000
}
.dropdown-menu .dropdown-item{
  padding:10px

}
body.dark .dropdown-menu .dropdown-item:hover{
  background-color: #0f1c36
}
body.light .dropdown-menu .dropdown-item:hover{
  background-color: #e7e7e7
}
.d-md-block {
  display: block !important
}
.dropdown-menu{
  display: none;
  background-clip: padding-box;
  background-color: inherit;
  border-radius: .25rem;
  color: inherit;
  display: none;
  float: left;
  font-size: 1rem;
  left: 0;
  list-style: none;
  margin: 0;
  min-width: 10rem;
  position: absolute;
  text-align: left;
  top: 100%;
  z-index: 1000;
}
.dropdown-menu.show{
  display: grid;

}
.dropdown-item{
  display: flex;
  justify-content: space-between;

}
.user-UL {
	font-size: 16px;
	background-color: #58e2c7 !important;
	padding: 8px 10px;
	border-radius: 5px;
	color: black;
	font-weight: 600;
	font-family: sans-serif;
}
.top-navbar-md{
  height: 53px
}
.btn-group{
  display: flex;
  border: none !important;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-group .btn{

  width: 80px;
}
.ltr{
  direction: ltr;
  text-align: left;

}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.card-header div{
  overflow: auto;
  widows: max-content
}

.card-header div a{
  color: #897777
}
body.light .card-header div a{
  color: #b99191
}
body.dark .card-header  a.active-tab{
  color: #fff;
  background-color: orange;
  border-radius: 20px;
  padding: 5px 10px
}
body.dark *.active-tab{
  color: #fff;
  background-color: orange;
  border-radius: 20px;
  padding: 5px 10px
}
.header{
  padding: 10px 15px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  height: 50px
}
body.dark .info-badge{
  border-radius: 15px;
  padding: 5px 20px;
  background-color: white;
  color: 000;
}
body.light .info-badge{
  border-radius: 15px;
  padding: 5px 20px;
  background-color: black;
  color: white;
}

body.light .header{
  background-color: #e7e7e7;
  color: inherit;
  border-right:3px solid #000
}
body.dark .header{
  color: inherit;
  border-right:3px solid cyan
}
body.light .card-header  a.active-tab{
  color: white !important;
  background-color: blue;
  border-radius: 20px;
  padding: 5px 10px
}
span.input-span{
  position: relative;
margin: 0 10px -50px 0;
bottom: 26px;
}

.btn-group > .btn:not(:last-child),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.cryptolist-table {
  width: 100%;
  height: 500px !important;
  overflow: auto;
  overflow-y: auto;
  padding-right: 10px
}
.asks .d-flex{
  direction: rtl;
  text-align: right;
  justify-content: space-between !important;
}
.bids .d-flex{
  direction: rtl;
  text-align: right;
  justify-content: space-between;
}
.bids .d-flex p{
  padding: 5px
}
body.dark .o-l-c{
  background-color: #161924;
}
input.is-invalid{
    border-color: #dc3545 !important;
}
input.is-valid{
    border-color: green !important;
}
body.light .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 3px;
    outline: 0;
    color: #000
}
body.dark .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #061621;
    color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 3px;
    outline: 0;
}
.h300{
  height: 300px
}
.mc-header {
  height: 38px;
  padding: 13px 20px 13px 20px;
  display: flex !important;
  justify-content: space-between;
  margin-bottom: 2px;
  background-color: #141722;

}
.activetab-m{
  color: cyan
}
body.dark .ps-menu-icon{
  color: #8cd9ff;
}
body.dark .ps-menu-icon *{
  color: #8cd9ff;
}
body.dark .ps-sidebar-container{
  color: #fff
}
body.light .ps-menu-icon{
  color: var(--gray10)
}
body.light .ps-menu-icon *{
  color: var(--gray10)
}
body.light .fixed-right-blue{
  position: fixed;
  background-color: #fff !important;
  color: #000;
  border-left: 1px solid #808080;
  width: 250px;
  z-index: 200
}
body.light .fixed-right-gray{
  position: fixed;
  background-color: #fff !important;
  color: #000;
  border-left: 1px solid #808080;
  width: 250px;
  z-index: 2000;
  right: 0
}
.sidebar{
  position: fixed;
  top:0;
  right: 0
}
body.light .fixed-right-gray aside{
  position: fixed;
  background-color: #fff !important;
  color: #000;
  border-left: 1px solid #808080;
  width: 250px;
  z-index: 200;
  height: 100%
}
body.light .sidebar aside{
  position: fixed;
  background-color: #ffffff00 !important;
  color: #000;
  border-left: 1px solid #808080;
  width: 250px;
  z-index: 200;
  padding-bottom: 200px;
  opacity: 1

}
body.light .sidebar {
  background-color: #fff;
  border-left: 1px solid #e7e7e7;
  color: #000;
  width: 250px
}
body.dark .sidebar {
  background-color: #000518
}
body.dark .ps-menu-button:hover{
    background-color: #222738 !important
}
.d-flex{
  align-items: center;
  vertical-align: middle;
}
.tx-detail-section-left{
  padding: 20px 0 20px 0
}
.mc-nav .mc-nav-item {
  padding: 0 20px 0 20px;
}
.mc-nav-item {
  position: relative;
}
.mc-nav-item-last {
  position: relative;
}
.mc-nav-item:first-child {
  position: relative;
}

.mc-nav  .mc-nav-item::before {
  content: '';
  height: 50%;
  width: 1px;

  position: absolute;
  left: 0;
  top: 10;

  background-color: #e7e7e740;
}

.mc-nav .mc-nav-item-last {
  padding: 0 20px 0 20px;
  /* border-right: 1px solid #e7e7e740 */
}
.mc-nav .mc-nav-item-last::before {
  content: '';
  height: 50%;
  width: 1px;

  position: absolute;
  right: 0;
  top: 10;

  background-color: #e7e7e740;
}
.mc-nav .quote-icon{
  position: absolute;
  width: 24px;
  height: 24px;
  left: 33px;
  top:23;
  z-index: 1
}
.sidebar{
  height: 100%;
  padding-bottom: 100px;
  bottom: 0;
  top:53px
}
body.dark .dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid #51a8ff4d;
}
body.dark .ps-menu-button{
}
body.light .ps-menu-button:hover{
    background-color: #e7e7e7 !important
}
body.light .ps-menu-button{
}
body.dark  .fixed-right-blue .ps-submenu-content{
  background-color: #00051800;
  padding-right: 40px;
}
body.light  .fixed-right-gray .ps-submenu-content{
  background-color: #000;
  padding-right: 40px;
}
 .ps-submenu-content{
  background-color: #181b2600 !important;
}
 .ps-sidebar-container{
  background-color: #181b2600 !important;
}
 .ps-submenu-content *{
  margin-right: 10px
}
