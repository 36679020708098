@media (max-width: 992px){
     .bm-menu-wrap{
         top: 52
    }
     .fixed-right-gray{
         position: fixed;
         top: 52
    }
     .rewards-badge{
         min-width: 100px !important;
         display: block;
         text-align: center;
         align-items: center;
    }
     .rw-container{
         margin: 0 20px 0 20px;
         border: none !important;
         display: block;
         background-color: #ffffff00 !important
    }
    .xs-slider-500 .carousel .slider-wrapper{
      height: auto;
    }
}
 @media (max-width: 576px) {
   .ltr-inputgroup-button{
     height: 50px;
     border-right: none;
     border: 1px solid #e7e7e7
   }
   .input-group-lg .input-custome-button{
     height: 50px !important
   }
   .input-group-lg.ltr input{
     border-top-left-radius: 0 !important;
     border-bottom-left-radius: 0 !important;
     border-top-right-radius: 3px !important;
     border-bottom-right-radius: 3px !important;
     border-color: #232731 !important;
     border: 1px solid #232731;
   }
   .input-group-lg.ltr .dropdown-menu{
     width: 50px !important
   }
   .input-group-lg.ltr .btn{
     border-top-left-radius: 3px !important;
     border-bottom-left-radius: 3px !important;
     border-top-right-radius: 0px !important;
     border-bottom-right-radius: 0px !important;
     border-color: #232731 !important;
     border: 1px solid #232731 !important;
     border-right: 1px solid #232731 !important;
     border-right: none !important
   }
   .input-group-lg.ltr{
     height: 50px;
     direction: ltr;
   }

     .icon-bell{
         font-size: 14px !important;
    }
     body.dark .bm-menu-wrap{
         background-color: #000518
    }
     body.light .bm-menu-wrap{
         background-color: #fff
    }
     .btn-primary{
         height: 40px
    }
     .btn-light{
         height: 40px
    }
     .btn-lg{
         height: 50px
    }
     .btn-lg{
         height: 50px
    }
     .input-group .btn{
         border-top-left-radius: 0px;
         border-bottom-left-radius: 0px;
         border-left: none
    }
     .sell-btn{
         height: 45px !important;
         font-size: 16px !important
    }
     .buy-btn{
         height: 45px !important;
         font-size: 16px !important
    }
     .icon-earphones{
         font-size: 14px !important;
    }
     body{
         overflow-y: auto;
         overflow-x: hidden;
         overflow: auto;
    }
     body.dark .toright-collapse .sticky-top{
         background-color: #181b26;
         padding-bottom: 50px;
         top:-10px
    }
     body.light .toright-collapse .sticky-top{
         background-color: #fff;
         padding-bottom: 50px;
         top:-10px
    }
     body{
         font-size: 10px!important
    }
     p{
         font-size: 10px
    }
     span{
         font-size: 10px
    }
     a{
         font-size: 10px
    }
     h6{
         font-size: 10px
    }
     .main-wrapper{
         padding: 20px 5px 50px 5px;
    }
     .card-body{
         padding: 5px
    }
     body.light .card{
         border: 1px solid #e7e7e7
    }
     body.dark .o-l-c{
         background-color: #181b26
    }
     .text-success{
         color: #26a17b !important
    }
     .dashboard-table thead{
         display: none !important
    }
     .card-header div{
         overflow: auto;
         overflow-x: auto;
         width: max-content
    }
     .card-header div a{
         padding: 0 0 0 20px;
    }
     body.dark .card-header div a{
         padding: 0 0 0 20px;
         color: #897777
    }
     body.light .card-header div a{
         padding: 5px 10px;
         color: #b99191
    }
     body.dark .card-header div a.active-tab{
         padding: 0 0 0 20px;
         color: #fff
    }
     body.light .card-header div a.active-tab{
         padding: 5px 10px;
         color: #000
    }
     body.dark .xs-overlay {
         border-top : none;
         border-left : none;
         border-right : none;
         border-bottom: 1px solid #e7e7e710 !important;
    }
     body.light .xs-overlay {
         border: none !important;
    }
     .crypto-tr{
         height: 70px
    }
     .xs-overlay .menuSection p {
         width: max-content
    }
     .absolute{
         position: absolute;
    }
     .crypto-td-7 .icon{
         margin-left: 10px
    }
     .crypto-td-7 .slug{
         margin-left: 40px;
         font-size: 14px;
         font-weight: bold;
    }
     .crypto-td-7 .price-cr{
         margin-left: 40px;
         font-size: 9px;
    }
     .crypto-td-7{
         width : 300px !important;
         direction: ltr;
         text-align: left
    }
     .crypto-td-1{
         display: none;
    }
     .crypto-td-2 span{
         font-family: monospace ;
         font-size: 12px
    }
     .crypto-td-2{
         width: 60px
    }
     .crypto-td-3{
         display: none
    }
     .crypto-td-4{
         display: none
    }
     .crypto-td-5{
         display: none
    }
     .crypto-td-6{
         display: none
    }
     .icon-row-slug{
         font-size: 9px
    }
     .round-btn{
         border: 1px solid #fff;
         color: white;
         text-align: center;
         text-decoration: none;
         font-size: 16px;
         margin: 4px 2px;
         cursor: pointer;
         border-radius: 50%;
         align-items: center;
         width: 40px;
         height: 40px;
         vertical-align: middle;
         align-items: center;
         padding: 3px;
         align-items: center;
    }
     .xs-overlay .menuSection {
         display: grid !important;
         width: max-content;
         padding: 10px 0 10px 30px;
         display: inline-block;
         margin: 0 0 0 20px;
         font-size: 11px;
    }
     .main-wrapper .sidebar{
         display: none !important
    }
     .top-navbar-md{
         display: none !important
    }
     .xs-overlay {
         padding: 10px 0 10px 30px;
         display: flex;
         vertical-align: middle;
         height: 80px;
         align-items: center;
         overflow-x: auto;
    }
     .top-navbar-md{
         display: none
    }
     body.dark .category-badge {
         font-size: 10px;
         width: 60px;
         margin: 0 10px 0 10px;
         cursor: pointer;
         display: table-cell;
         text-align: center;
         padding: 5px 5px 5px 5px;
         border: 1px solid #9292921f;
         border-radius: 5px;
         background-color: #13161f;
    }
     body.dark .active-category-badge {
         font-size: 10px;
         width: 60px;
         margin: 0 10px 0 10px;
         cursor: pointer;
         display: table-cell;
         text-align: center;
         padding: 5px 5px 5px 5px;
         border: 1px solid #9292921f;
         border-radius: 5px;
         color: #fff;
         background-color: var(--mint);
    }
     body.light .category-badge {
         font-size: 10px;
         width: 60px;
         margin: 0 10px 0 10px;
         cursor: pointer;
         display: table-cell;
         text-align: center;
         padding: 5px 5px 5px 5px;
         border: 1px solid #9292921f;
         border-radius: 5px;
         background-color: #b1b1b1;
         color: #000
    }
     body.light .active-category-badge {
         font-size: 10px;
         width: 60px;
         margin: 0 10px 0 10px;
         cursor: pointer;
         display: table-cell;
         text-align: center;
         padding: 5px 5px 5px 5px;
         border: 1px solid #9292921f;
         border-radius: 5px;
         color: #000;
         background-color: var(--mint);
    }
     body.dark .form-text {
         color: #d0d4dc;
         font-size: 10px;
    }
     body.light .form-text {
         color: #d0d4dc;
         font-size: 10px;
    }
     .switch-side-btn.sell .btntextsell {
         position: absolute;
         right: 61% !important;
         margin-top: 7px;
         z-index: 1099;
         color: #fff
    }
     .market-layer .btn-danger {
         background-color: var(--neon);
         border: 1px solid var(--neon);
    }
     .market-layer .btn-success {
         background-color: var(--mint);
         border: 1px solid var(--mint);
    }
     .switch-side-btn.buy .btntextbuy {
         position: absolute;
         right: 32% !important;
         margin-top: 7px;
         z-index: 1099;
         color: #fff
    }
     .switch-side-btn.sell::after {
         content: '';
         width: 60%;
         position: fixed;
         height: 35px;
         border-radius: 30px;
         background-color: var(--neon);
         left: 26px;
         text-align: center;
         border: 1px solid var(--neon);
         margin-top: -1px
    }
     .switch-side-btn.buy::after {
         content: '';
         width: 60%;
         position: fixed;
         height: 35px;
         border-radius: 30px;
         background-color: var(--mint);
         right: 26px;
         text-align: center;
         border: 1px solid var(--mint);
         margin-top: -1px
    }
     .switch-side-btn {
         margin: 10px 20px 10px 20px;
         height: 35px;
         border-radius: 30px;
         background-color: #3a3333;
         border: 1px solid #75757520;
    }
     .orderform-collapse .closebtn {
         position: fixed;
         left: 15px;
         top: 15px
    }
     body.dark .market-layer input.market-input {
         background-color: #181b26;
         margin: 10px 0 10px 0;
         border: 1px solid 393939;
         height: 36px;
         font-size: 11px;
         text-align: right;
         direction: rtl;
         padding: 0 10px 0 20px
    }
     body.light .market-layer input.market-input {
         background-color: #fff;
         margin: 10px 0 10px 0;
         border: 1px solid 393939;
         height: 36px;
         font-size: 11px;
         text-align: right;
         direction: rtl;
         padding: 0 10px 0 20px
    }
     .market-input-symbol {
         bbackground-color: #fff0;
         color: #757575 !important;
         cursor: pointer;
         display: block;
         font-size: 14px;
         font-size: 10px;
         height: 17px;
         left: 0;
         margin-top: -50px;
         padding: 3px;
         position: relative;
         right: auto;
         z-index: 2;
         float: left;
    }
     body.dark .orderform-collapse {
         background-color: #181b26;
         position: fixed;
         top:150px;
         width: 100%;
         padding: 10px 5px 10px 5px;
         color: gray;
         border-top: 4px solid #404067;
         left: 0;
         bottom: 0;
    }
     body.light .orderform-collapse {
         background-color: #fff;
         position: fixed;
         top:150px;
         width: 100%;
         padding: 10px 5px 10px 5px;
         color: #000;
         border-top: 4px solid #000;
         left: 0;
         bottom: 0;
    }
     body.dark .toright-collapse {
         background-color: #181b26;
         position: fixed;
         width: 100%;
         padding: 10px 5px 10px 5px;
         color: gray;
         height: 100%;
         overflow: auto;
         z-index: 8;
         padding: 60px 0 50px 0;
         bottom: 58px
    }
     body.light .toright-collapse {
         background-color: #fff;
         position: fixed;
         bottom: 56px;
         width: 100%;
         padding: 10px 5px 10px 5px;
         color: gray;
         height: 100%;
         overflow: auto;
         z-index: 8;
         padding: 60px 0 50px 0
    }
     .toright-collapse-row .symbol-row {
         min-width: 100px !important;
         font-size: 11px;
         text-align: left;
    }
     body.dark .symbol-row.slug {
         min-width: 100px !important;
         font-size: 12px;
         text-align: left;
         font-weight: bold;
         color: #fff
    }
     body.light .symbol-row.slug {
         min-width: 100px !important;
         font-size: 12px;
         text-align: left;
         font-weight: bold;
         color: #000
    }
     .symbol-row.icon {
         min-width: 50px !important;
         font-size: 12px;
         text-align: left;
         font-weight: bold;
         color: #fff
    }
     .toright-collapse-row {
         justify-content: space-between;
    }
     .toright-collapse input {
         text-align: left !important;
         direction: ltr !important;
         font-size: 14px !important;
         margin: 10px !important
    }
     .toright-collapse-row.header {
         justify-content: space-between;
         border-bottom: 1px solid #232731;
         margin: 20px 0 20px 50px;
         padding-bottom: 10px
    }
     .chart-xl-bottom{
         height: 400px;
    }
     .order-form-row:last-child {
         margin-right: auto;
    }
     .order-form-row p{
         font-size: 13px !important
    }
     .order-form-row {
         direction: rtl;
         display: flex;
         justify-content: flex-start;
         padding: 10px
    }
     body.dark .market-bottom {
         display: flex;
         position: fixed;
         z-index: 51;
         bottom: 0;
         left: 0;
         width: 100%;
         background-color: #000;
         border-top: 1px solid 757575;
         justify-content: space-between;
         padding: 5px 10px 30px 10px;
    }
     .dropdown-menu{
         width: 100%
    }
     .dropdown-menu input{
         height: 40px !important
    }
     body.light .market-bottom {
         display: flex;
         position: fixed;
         z-index: 51;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 58px;
         background-color: #e7e7e7;
         border-top: 1px solid 757575;
         justify-content: space-between;
         padding: 0 5px
    }
     .market-bottom .btn {
         border-radius: 25px;
         height: 40px;
         margin-top: 10px;
         font-size: 14px
    }
    /* .order-form-row small{
         font-size: 12px
    }
     */
     body.dark .market-layer {
         background-color: #181b26;
         padding-bottom: 300px;

    }
     body.dark input.market-input {
         margin: 10px 0 20px 0;
         width: 100%;
         padding-left: 10px;
         color: #fff !important;
         height: 40px;
         font-family: inherit;
         word-wrap: break-word;
    }
     body.light input.market-input {
         margin: 10px 0 20px 0;
         width: 100%;
         padding-left: 10px;
         color: #000 !important;
         height: 40px;
         font-family: inherit;
         word-wrap: break-word;
    }
     .mc-header-2 {
         height: 23px;
         padding: 5px;
         display: flex;
         flex-direction: row-reverse;
    }
     .asks:first-child .text-success {
         color: #06bc8d;
         vertical-align: middle;
         margin: auto auto 0 0;
         align-self: center;
         display: block;
         padding: 5px
    }
     .asks:first-child p {
         vertical-align: middle;
         align-self: center;
         display: block;
         padding: 5px
    }
     body.dark .mc-header-3 {
         height: 38px;
         text-align: center;
         background-color: #2b2e39;
         padding: 1px 0 1px 0;
         vertical-align: middle;

    }
     body.dark .mc-header-3 p {
         margin: 1px 0 1px 10px;
         padding: 5px 0 5px 0;
         background-color: #181b26;
         height: 34px
    }
     body.light .mc-header-3 {
         height: 32px;
         text-align: center;
         background-color: #e7e7e7;
         padding: 1px 0 1px 0;
    }
     body.light .mc-header-3 p {
         margin: 1px;
         padding: 5px 0 8px 0;
         background-color: #fff
    }
     .bids .text-danger {
         color: #f56754;
         vertical-align: middle;
         margin: auto auto 0 0;
         align-self: center;
         display: block;
         padding: 5px
    }
     .bids p {
         vertical-align: middle;
         display: block;
    }
     .asks:first-child {
         padding: 10px;
         display: flex;
         justify-content: space-between;
         vertical-align: middle;
    }
     .bids:first-child {
         padding: 10px;
         display: flex;
         justify-content: space-between;
         vertical-align: middle;
    }
     .asks {
         height: 180px;
         overflow-y: auto;
         display: flex;
         flex-direction: column-reverse !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
    }
     #ask {
         height: 100%;
         overflow-y: auto;
         display: flex;
         flex-direction: column !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
         line-height: 24px
    }
     #bid {
         height: 100%;
         overflow-y: auto;
         display: flex;
         flex-direction: column !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
         line-height: 24px
    }
     .bids {
         height: 245px;
         overflow-y: auto;
         display: flex;
         flex-direction: column !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
         padding: 5px
    }
     #ask-bid {
         height: 400px
    }
     .sidebar {
         height: 100%;
         width: 250px;
         position: fixed;
         top: 0;
         right: 0;
         z-index: 3;
         background-color: #000;
         border-left : 1px solid #e7e7e730
    }
     .ps-sidebar-root {
         border: none !important
    }
     .mc-nav {
         display: none
    }
     .mc-nav-xs {
         display: flex;
         height: 50px;
         position: fixed;
         top: 0;
         width: 100%;
         background-color: #000;
         z-index: 5;
         justify-content: space-between;
         padding: 0 10px 0 10px;
         vertical-align: middle;
         align-items: center;
         color: #fff
    }
     .chart-xl {
         height: 300px
    }
     .main-auth {
         padding-bottom: 100px
    }
     .main-auth .btn {
         height: 50px
    }
     body.dark ::marker {
         color: var(--orange);
    }
     #footer{
         padding-bottom: 100px;
    }
     body.dark {
         input:-webkit-autofill, input:-webkit-autofill:active, input:-webkit-autofill:focus, input:-webkit-autofill:hover {
             -webkit-text-fill-color: #fff;
             -webkit-background-clip: text;
             box-shadow: inset 0 0 20px 20px #23232329;
        }
    }
     body.light {
         input:-webkit-autofill, input:-webkit-autofill:active, input:-webkit-autofill:focus, input:-webkit-autofill:hover {
             -webkit-text-fill-color: #000518;
             -webkit-background-clip: text;
             box-shadow: inset 0 0 20px 20px #fff;
        }
    }
     body.dark input {
         background-color: #000518;
         color: #fff;
         height: 40px;
         border: 1px solid #232731;
         border-radius: 3px;
         direction: rtl;
    }
     body.light input {
         background-color: #fff;
         color: #000;
         width: 100%;
         height: 20px;
         border: 1px solid var(--gray-1);
         border-radius: 3px;
         direction: rtl !important;
    }
     .form-label {
         margin-bottom: 20px;
         margin-right: 20px;
         display: list-item;
    }
     .btn {
         display: block;
         text-align: center;
         align-items: center;
         vertical-align: middle;
         padding: 10px 0 10px 0;
         border-radius: 5px;
    }
     .xs-navbar .container-fluid {
         display: flex;
         flex-direction: row;
         float: right;
         justify-content: space-between;
         font-size: 10px
    }
     .xs-navbar {
         background-color: #000;
         bottom: 0px !important;
         color: #fff;
         position: fixed !important;
         width: 100%;
         z-index: 50;
         padding: 10 0 30px 0;
    }
     body.dark .coin-list-row, body.light .coin-list-row {
         background-color: #1c1c39
    }
     body.light .coin-list-row, body.light .coin-list-row {
         background-color: #e5e5ff
    }
     body.light .coin-list-row {
         border-radius: 2px;
         cursor: pointer;
         display: block;
         display: flex;
         font-size: inherit;
         height: 55px;
         justify-content: space-between;
         margin-bottom: 8px;
         vertical-align: middle;
         align-items: center;
    }
     body.dark .coin-list-row {
         border-radius: 2px;
         cursor: pointer;
         display: block;
         display: flex;
         font-size: 12px;
         height: 55px;
         justify-content: space-between;
         margin-bottom: 8px;
         vertical-align: middle;
         align-items: center;
    }
     body.light .scrollHider {
         background-color: #e7e7e7;
         padding: 1px 0 0 0;
         margin: 1px 0 0 0;
    }
     body.dark .scrollHider {
         background-color: #2b2e39;
         padding: 1px 0 0 0;
         margin: 1px 0 0 0;
    }
     .scrollHider {
         align-items: center;
         background-color: inherit;
         display: flex !important;
         width: 100%;
         overflow-x: auto;
         overflow-y: hidden;
    }
     body.light .menuBox {
         background-color: #fff;
         margin: 1px;
         overflow: auto;
         overflow-y: hidden;
    }
     body.dark .menuBox {
         background-color: #151823;
         margin: 1px;
         overflow: auto;
         overflow-y: hidden;
    }
     body.dark .menuBox.home {
         background-color: #000518;
         margin: 1px;
         overflow-y: hidden;
    }
     body.dark .scrollHider.home {
         background-color: #000518;
         margin: 1px;
         border-bottom: 1px solid var(--gray-10)
    }
     .menuBox {
         direction: rtl !important;
         display: flex !important;
         white-space: nowrap;
         vertical-align: middle;
         align-items: center;
         flex-direction: row;
         height: 32px;
         width: 100%;
         overflow-y: hidden;
    }
     body.dark .menuBox * {
         padding: 10px 20px 9px 20px;
         color: #848484;
         font-size: 11px;
         width: 120px;
         text-align: center;
    }
     body.dark .menuBox .activetab-m {
         color: #fff;
         font-size: 11px;
         font-weight: 600;
         text-align: center;
    }
     body.light .menuBox * {
         padding: 10px 20px 9px 20px;
         color: #848484;
         font-size: 11px;
         width: fit-content;
         text-align: center;
    }
     body.light .menuBox .activetab-m {
         color: #000;
         font-size: 11px;
         font-weight: 600;
         text-align: center;
    }
     .menuBox * {
         display: inline-block;
         min-width: max-content;
         max-width: 100px;
    }
     .slider-slide5-bg {
         content: '';
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0;
         left: 0;
         z-index: 1;
         background-image: url('./images/sbg05.png');
         background-size: cover;
         background-repeat: no-repeat no-repeat;
         background-position: top;
         opacity: 1
    }
     .slider-slide1-bg {
         content: '';
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0;
         left: 0;
         z-index: 1;
         background-image: url('./images/sbg05.png');
         background-size: cover;
         background-repeat: no-repeat no-repeat;
         background-position: top;
         opacity: 1
    }
     .slider-slide3-bg {
         content: '';
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0;
         left: 0;
         z-index: 1;
         background-image: url('./images/sbg04.png');
         background-size: cover;
         background-repeat: no-repeat no-repeat;
    }
     .slider-slide4-bg {
         content: '';
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0;
         left: 0;
         z-index: 1;
         background: url('./images/sbg05.png');
         background-size: cover;
         background-repeat: no-repeat no-repeat;
         background-position: center;
         opacity: 1
    }
     .slider-slide2-bg {
         content: '';
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0;
         left: 0;
         z-index: 1;
         background: url('./images/sbg05.png');
         background-size: cover;
         background-repeat: no-repeat no-repeat;
         background-position: center;
         opacity: 1
    }
     .slider-slide1 {
         opacity: 1;
         width: calc(100%);
         height: 550px;
         z-index: 2;
         position: inherit;
         font-family: shabnam !important
    }
     .slider-slide4 {
         opacity: 1;
         width: calc(100%);
         height: 550px;
         z-index: 2;
         position: inherit;
         font-family: shabnam !important
    }
     .slider-slide3 {
         opacity: 1;
         width: calc(100%);
         z-index: 2;
         position: inherit;
         font-family: shabnam !important
    }
     .slider-slide2 {
         opacity: 1;
         width: calc(100%);
         height: 550px;
         z-index: 2;
         position: inherit;
         font-family: shabnam !important
    }
     .slide span {
         font-size: 9px;
         display: inline-block;
    }
     .control-dots {
         margin-top: 50px !important;
    }
     h6 {
         color: inherit;
         font-family: inherit;
         font-weight: 500;
         line-height: 1.2;
         font-size: 1rem
    }
     .carousel .slider-wrapper {
         height: 150px
    }
     body.dark .carousel .control-dots .dot {
         background-color: orange;
    }
     body.light p.slider-p {
         color: #fff;
         font-size: 11px;
         margin: 0 20px 0 10px
    }
     .carousel {
         border-radius: 10px
    }
     body.light .carousel .control-dots .dot {
         background-color: blue;
    }
     .control-dots .selected {
         background: #0f0f00;
         height: 3px !important;
         width: 10px !important;
    }
     .carousel .control-dots .dot {
         height: 3px;
         width: 3px;
         border-radius: 2px;
         box-shadow: none
    }
     .top-bar {
         height: 50px;
         display: flex;
         flex-direction: row;
         justify-content: flex-start;
         direction: rtl;
         text-align: right;
         font-family: cursive;
         align-items: center;
         vertical-align: middle;
         padding: 0 10px 0 10px;
    }
     body.light .xs-login-card {
         border: 1px solid var(--gray-1);
         border-radius: 15px;
    }
     .xs-login-card .right-login-col {
         text-align: right;
         width: 80%;
    }
     .badg-dark-signup {
         border-radius: 20px;
         display: flex;
         margin-top: auto;
         margin-bottom: auto;
         width: 140px;
         height: 37px;
         padding: 10px;
    }
     .badg-dark-signup span{
         vertical-align: middle;
        /* margin: auto;
         */
    }
     .badg-light-green span{
         vertical-align: middle;
    }
     body.light .badg-dark-signup {
         background-color: #000;
         padding: 5px 10px 5px 10px;
         vertical-align: middle;
         align-items: center;
         color: #fff
    }
     body.dark .badg-dark-signup {
         background-color: #1b3b6e
    }
     .badg-light-green {
         background-color: #58e2c7;
         border-radius: 20px;
         color: #000 !important;
         display: flex;
         margin-top: auto;
         margin-bottom: auto;
         width: 140px;
         height: 37px;
    }
     .xs-login-card {
         margin: 0px 10px 20px 10px !important;
         padding: 13px 13px !important;
         height: 152px;
    }
     html {
         font-family: -apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif;
    }
     body {
         padding-top: 50px;
         text-align: right;
         font-family: -apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif;
    }
     body.dark {
         background-color: #000518;
         color: #fff;
         overflow: auto;
         width: auto;
         overflow-y: auto;
         overflow-x: hidden !important
    }
     .top-row {
         position: fixed;
         top: 0;
         left: 0;
         right: 0
    }
     body.light .top-row {
         border-bottom: 1px solid var(--gray-1);
         background-color: #fff;
         z-index: 2
    }
     body.dark .top-row {
         border-bottom: 1px solid var(--gray-10);
         background-color: #000518;
         z-index: 2
    }
     body.dark .top-bar svg {
         color: var(--gray-1);
    }
     body.dark .top-bar .icon-round {
         color: var(--gray-1);
    }
     .top-bar .icon-round {
         height: 32px;
         margin: auto 5px auto 7px;
         align-self: center;
         align-content: center;
         cursor: pointer;
    }
     .top-bar .topbar-input {
         width: calc(100% - 130px);
         margin: 0 15px 0 15px;
    }
     body.dark .top-bar .topbar-input input {
         border: 1px solid #3a475d;
    }
     body.light .top-bar .topbar-input input {
         border: 1px solid var(--gray-1);
    }
     .top-bar .topbar-input input {
         width: 100%;
         height: 35px;
         font-size: 21px;
         text-align: left;
         padding: 0 0 0 10px;
         border-radius: 20px
    }
     body.dark .market-sidebar {
         background-color: #000518;
         color: #fff;
         height: 100%;
         width: 250px;
         position: fixed;
         top: 0;
         right: 0;
         z-index: 3;
    }
     body.light .market-sidebar {
         background-color: #000518;
         color: #fff;
         height: 100%;
         width: 250px;
         position: fixed;
         top: 0;
         right: 0;
         z-index: 58;
    }
     .tx-modal .modal-content{
         height: 100% !important;
         width: 100% !important;
    }
     .modal-dialog.tx-modal{
         width: auto !important;
         left: 0;
         height: 100%;
         position: relative;
         top: 0;
         right: 0;
         margin-top: 0 !important
    }
     .market-list-modal{
         height: 500px;
         overflow: auto;
    }
     .tx-modal .modal-header .icon-close{
         margin-left: auto;
    }
     .tx-modal .sticky-top{
         top: 80px;
         height: 100px;
    }
     .tx-modal .modal-header .modal-title{
         width: 100%;
    }
     .tx-modal .modal-header svg{
    }
     .toright-collapse-row{
         justify-content: space-between;
         display: flex;
         padding: 10px 0 10px 20px
    }
     .tx-modal .modal-header{
         justify-content: space-between;
         position: fixed;
         top: 0;
         width: 100%;
    }
     input.market-search-input{
         width: 100%;
         margin-right: auto;
         border: 1px solid #e7e7e740 !important;
    }
}
 @media (min-width: 577px)and (max-width: 767px) {
     .icon-bell{
         font-size: 14px !important;
    }
     .chart-xl-bottom{
         height: 400px;
         background-color: #181b26
    }
     .icon-earphones{
         font-size: 14px !important;
    }
     .icon-round svg{
         font-size: 14px !important;
    }
     body.dark .toright-collapse .sticky-top{
         background-color: #181b26;
         padding-bottom: 50px;
         top:-10px
    }
     body.light .toright-collapse .sticky-top{
         background-color: #fff;
         padding-bottom: 50px;
         top:-10px
    }
     .crypto-tr{
         height: 70px
    }
     .xs-overlay {
         border: none !important;
    }
     .dashboard-table thead{
         display: none !important
    }
     .crypto-td-7 .icon{
         margin-left: 10px
    }
     .crypto-td-7 .slug{
         margin-left: 40px;
         font-size: 14px;
         font-weight: bold;
    }
     .crypto-td-7 .price-cr{
         margin-left: 40px;
         font-size: 9px;
    }
     .crypto-td-7{
         width : 300px !important;
         direction: ltr;
         text-align: left
    }
     .crypto-td-1{
         display: none;
    }
     .crypto-td-2 span{
         font-family: monospace ;
         font-size: 12px
    }
     .crypto-td-2{
         width: 60px
    }
     .crypto-td-3{
         display: none
    }
     .crypto-td-4{
         display: none
    }
     .crypto-td-5{
         display: none
    }
     .crypto-td-6{
         display: none
    }
     body.dark .round-btn{
         background-color: #fff;
         color: #000
    }
     body.dark .deposit-r-btn{
         padding: 20px;
         border: 2px solid #fff;
         border-radius: 50%;
    }
     body.light .deposit-r-btn{
         padding: 20px;
         border: 2px solid #000;
         border-radius: 50%;
    }
     body.dark .send-btn{
         padding: 20px;
         border: 2px solid #fff;
         border-radius: 50%;
    }
     body.light .send-btn{
         padding: 20px;
         border: 2px solid #000;
         border-radius: 50%;
    }
     body.dark .recieve-btn{
         padding: 20px;
         border: 2px solid #fff;
         border-radius: 50%;
    }
     body.light .recieve-btn{
         padding: 20px;
         border: 2px solid #000;
         border-radius: 50%;
    }
     body.dark .withdraw-btn{
         padding: 20px;
         border: 2px solid #fff;
         border-radius: 50%;
    }
     body.light .withdraw-btn{
         padding: 20px;
         border: 2px solid #000;
         border-radius: 50%;
    }
     body.dark .history-btn{
         padding: 20px;
         border: 2px solid #fff;
         border-radius: 50%;
    }
     body.light .history-btn{
         padding: 20px;
         border: 2px solid #000;
         border-radius: 50%;
    }
     .icon-row-slug{
         font-size: 9px
    }
     .round-btn{
         border: 1px solid #fff;
         color: white;
         text-align: center;
         text-decoration: none;
         font-size: 16px;
         margin: 4px 2px;
         cursor: pointer;
         border-radius: 50%;
         align-items: center;
         width: 40px;
         height: 40px;
         vertical-align: middle;
         align-items: center;
         padding: 3px;
         align-items: center;
    }
     .xs-overlay .menuSection {
         display: grid !important;
         width: max-content;
         padding: 10px 0 10px 30px;
         display: inline-block;
         margin: 0 0 0 20px;
         font-size: 11px;
    }
     .absolute{
         position: absolute;
    }
     .top-navbar-md{
         display: none !important
    }
     .market-bottom{
         display: none
    }
     body.dark .market-sidebar {
         background-color: #161924;
         color: #fff;
         height: 100%;
         width: 250px;
         position: fixed;
         top: 0;
         right: 0;
         z-index: 3;
    }
     body.light .market-sidebar {
         background-color: #fff;
         color: #161924;
         height: 100%;
         width: 250px;
         position: fixed;
         top: 0;
         right: 0;
         z-index: 3;
    }
     body.dark .category-badge {
         font-size: 10px;
         width: 60px;
         margin: 0 10px 0 10px;
         cursor: pointer;
         display: table-cell;
         text-align: center;
         padding: 5px 5px 5px 5px;
         border: 1px solid #9292921f;
         border-radius: 5px;
         background-color: #13161f;
    }
     body.dark .active-category-badge {
         font-size: 10px;
         width: 60px;
         margin: 0 10px 0 10px;
         cursor: pointer;
         display: table-cell;
         text-align: center;
         padding: 5px 5px 5px 5px;
         border: 1px solid #9292921f;
         border-radius: 5px;
         color: #fff;
         background-color: var(--mint);
    }
     body.light .category-badge {
         font-size: 10px;
         width: 60px;
         margin: 0 10px 0 10px;
         cursor: pointer;
         display: table-cell;
         text-align: center;
         padding: 5px 5px 5px 5px;
         border: 1px solid #9292921f;
         border-radius: 5px;
         background-color: #b1b1b1;
         color: #000
    }
     body.light .active-category-badge {
         font-size: 10px;
         width: 60px;
         margin: 0 10px 0 10px;
         cursor: pointer;
         display: table-cell;
         text-align: center;
         padding: 5px 5px 5px 5px;
         border: 1px solid #9292921f;
         border-radius: 5px;
         color: #000;
         background-color: var(--mint);
    }
     body.dark .form-text {
         color: #d0d4dc;
         font-size: 10px;
    }
     body.light .form-text {
         color: #d0d4dc;
         font-size: 10px;
    }
     .switch-side-btn.sell .btntextsell {
         position: absolute;
         right: 61% !important;
         margin-top: 5px;
         z-index: 1099;
         color: #fff
    }
     .market-layer .btn-danger {
         background-color: var(--neon);
         border: 1px solid var(--neon);
    }
     .market-layer .btn-success {
         background-color: var(--mint);
         border: 1px solid var(--mint);
    }
     .switch-side-btn.buy .btntextbuy {
         position: absolute;
         right: 32% !important;
         margin-top: 5px;
         z-index: 1099;
         color: #fff
    }
     .switch-side-btn.sell::after {
         content: '';
         width: 60%;
         position: fixed;
         height: 35px;
         border-radius: 30px;
         background-color: var(--neon);
         left: 26px;
         text-align: center;
         border: 1px solid var(--neon);
         margin-top: -1px
    }
     .switch-side-btn.buy::after {
         content: '';
         width: 60%;
         position: fixed;
         height: 35px;
         border-radius: 30px;
         background-color: var(--mint);
         right: 26px;
         text-align: center;
         border: 1px solid var(--mint);
         margin-top: -1px
    }
     .switch-side-btn {
         margin: 10px 20px 10px 20px;
         height: 35px;
         border-radius: 30px;
         background-color: #3a3333;
         border: 1px solid #75757520;
    }
     .orderform-collapse .closebtn {
         position: fixed;
         left: 15px;
         top: 15px
    }
     body.dark .market-layer input.market-input {
         background-color: #181b26;
         margin: 10px 0 10px 0;
         border: 1px solid 393939;
         height: 36px;
         font-size: 11px;
         text-align: right;
         direction: rtl;
         padding: 0 10px 0 20px
    }
     body.light .market-layer input.market-input {
         background-color: #fff;
         margin: 10px 0 10px 0;
         border: 1px solid 393939;
         height: 36px;
         font-size: 11px;
         text-align: right;
         direction: rtl;
         padding: 0 10px 0 20px
    }
     .market-input-symbol {
         background-color: #fff0;
         color: #757575 !important;
         cursor: pointer;
         display: block;
         font-size: 14px;
         font-size: 10px;
         height: 17px;
         left: 12px;
         margin-top: -50px;
         padding: 3px;
         position: absolute;
         right: auto;
         text-align: right;
         z-index: 2;
    }
     body.dark .orderform-collapse {
         background-color: #181b26;
         position: fixed;
         bottom: 56px;
         width: 100%;
         padding: 10px 5px 10px 5px;
         color: gray;
         min-height: 500px
    }
     body.light .orderform-collapse {
         background-color: #fff;
         position: fixed;
         bottom: 56px;
         width: 100%;
         padding: 10px 5px 10px 5px;
         color: #000;
         min-height: 500px
    }
     body.dark .toright-collapse {
         background-color: #181b26;
         position: fixed;
         bottom: 56px;
         width: 100%;
         padding: 10px 5px 10px 5px;
         color: gray;
         height: 100%;
         overflow: auto;
         z-index: 8;
         padding: 60px 0 50px 0
    }
     body.light .toright-collapse {
         background-color: #fff;
         position: fixed;
         bottom: 56px;
         width: 100%;
         padding: 10px 5px 10px 5px;
         color: gray;
         height: 100%;
         overflow: auto;
         z-index: 8;
         padding: 60px 0 50px 0
    }
     .toright-collapse-row .symbol-row {
         min-width: 100px !important;
         font-size: 11px;
         text-align: left;
    }
     body.dark .symbol-row.slug {
         min-width: 100px !important;
         font-size: 12px;
         text-align: left;
         font-weight: bold;
         color: #fff
    }
     body.light .symbol-row.slug {
         min-width: 100px !important;
         font-size: 12px;
         text-align: left;
         font-weight: bold;
         color: #000
    }
     .symbol-row.icon {
         min-width: 50px !important;
         font-size: 12px;
         text-align: left;
         font-weight: bold;
         color: #fff
    }
     .toright-collapse-row {
         justify-content: space-between;
    }
     .toright-collapse input {
         text-align: left !important;
         direction: ltr !important;
         font-size: 14px !important;
         margin: 10px !important
    }
     .toright-collapse-row.header {
         justify-content: space-between;
         border-bottom: 1px solid #232731;
         margin: 20px 0 20px 50px;
         padding-bottom: 10px
    }
     .order-form-row:last-child {
         margin-right: auto;
    }
     .order-form-row {
         direction: rtl;
         display: flex;
         justify-content: flex-start;
         padding: 10px
    }
     body.dark .market-bottom {
         display: flex;
         position: fixed;
         z-index: 51;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 58px;
         background-color: #000518;
         border-top: 1px solid 757575;
         justify-content: space-between;
         padding: 0 5px
    }
     body.light .market-bottom {
         display: flex;
         position: fixed;
         z-index: 51;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 58px;
         background-color: #e7e7e7;
         border-top: 1px solid 757575;
         justify-content: space-between;
         padding: 0 5px
    }
     .market-bottom .btn {
         border-radius: 25px;
         height: 40px;
         margin-top: 10px
    }
     body.dark .market-layer {
         background-color: #181b26;
         height: 100%;
    }
     .mc-header-2 {
         height: 23px;
         padding: 5px;
         display: flex;
         flex-direction: row-reverse;
    }
     .asks:first-child .text-success {
         color: #06bc8d;
         vertical-align: middle;
         margin: auto auto 0 0;
         align-self: center;
         display: block;
         padding: 5px
    }
     .asks:first-child p {
         vertical-align: middle;
         align-self: center;
         display: block;
         padding: 5px
    }
     body.dark .mc-header-3 {
         height: 32px;
         text-align: center;
         background-color: #2b2e39;
         padding: 1px 0 1px 0;
    }
     body.dark .mc-header-3 p {
         margin: 1px;
         padding: 5px 0 5px 0;
         background-color: #181b26;
         height: 28px
    }
     body.light .mc-header-3 {
         height: 32px;
         text-align: center;
         background-color: #e7e7e7;
         padding: 1px 0 1px 0;
    }
     body.light .mc-header-3 p {
         margin: 1px;
         padding: 5px 0 5px 0;
         background-color: #fff
    }
     .bids .text-danger {
         color: #f56754;
         vertical-align: middle;
         margin: auto auto 0 0;
         align-self: center;
         display: block;
         padding: 5px
    }
     .bids p {
         vertical-align: middle;
         margin: auto 0 0 auto;
         align-self: center;
         display: block;
         padding: 5px
    }
     .asks:first-child {
         padding: 10px;
         display: flex;
         justify-content: space-between;
         vertical-align: middle;
    }
     .asks {
         height: 245px;
         overflow-y: auto;
         display: flex;
         flex-direction: column-reverse !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
    }
     #ask {
         height: 100%;
         overflow-y: auto;
         display: flex;
         flex-direction: column !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
         line-height: 24px
    }
     #bid {
         height: 100%;
         overflow-y: auto;
         display: flex;
         flex-direction: column !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
         line-height: 24px
    }
     .bids {
         height: 245px;
         overflow-y: auto;
         display: flex;
         flex-direction: column !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
         padding: 5px
    }
     #ask-bid {
         height: 400px
    }
     .sidebar {
         height: 100%;
         width: 250px;
         position: fixed;
         top: 0;
         right: 0;
         z-index: 3;
         background-color: #000518
    }
     .ps-sidebar-root {
         border: none !important
    }
     .mc-nav {
         display: none
    }
     body.dark .o-l-c{
         background-color: #181b26
    }
     .mc-nav-xs {
         display: flex;
         height: 50px;
         position: fixed;
         top: 0;
         width: 100%;
         background-color: #000;
         z-index: 5;
         justify-content: space-between;
         padding: 0 10px 0 10px;
         vertical-align: middle;
         align-items: center;
         color: #fff
    }
     .chart-xl {
         height: 300px
    }
     .main-auth {
         padding-bottom: 100px
    }
     .main-auth .btn {
         height: 50px
    }
     body.dark ::marker {
         color: var(--orange);
    }
     body.dark {
         input:-webkit-autofill, input:-webkit-autofill:active, input:-webkit-autofill:focus, input:-webkit-autofill:hover {
             -webkit-text-fill-color: #fff;
             -webkit-background-clip: text;
             box-shadow: inset 0 0 20px 20px #23232329;
        }
    }
     body.light {
         input:-webkit-autofill, input:-webkit-autofill:active, input:-webkit-autofill:focus, input:-webkit-autofill:hover {
             -webkit-text-fill-color: #000518;
             -webkit-background-clip: text;
             box-shadow: inset 0 0 20px 20px #fff;
        }
    }
     body.dark input {
         background-color: #000518;
         color: #fff;
         width: 100%;
         height: 50px;
         border: 1px solid #232731;
         border-radius: 3px
    }
     body.light input {
         background-color: #fff;
         color: #000;
         width: 100%;
         height: 50px;
         border: 1px solid var(--gray-1);
         border-radius: 3px
    }
     .form-label {
         margin-bottom: 20px;
         margin-right: 20px;
         display: list-item;
    }
     .btn {
         width: 200px;
         display: block;
         text-align: center;
         align-items: center;
         vertical-align: middle;
         padding: 10px 0 10px 0;
         border-radius: 5px;
    }
     .xs-navbar .container-fluid {
         display: flex;
         flex-direction: row;
         float: right;
         justify-content: space-between;
         font-size: 10px
    }
     .xs-navbar {
         background-color: #000;
         bottom: 0px !important;
         color: #fff;
         position: fixed !important;
         width: 100%;
         z-index: 50;
         padding: 10px;
    }
     body.dark .coin-list-row, body.light .coin-list-row {
         background-color: #1c1c39
    }
     body.light .coin-list-row, body.light .coin-list-row {
         background-color: #e5e5ff
    }
     body.light .coin-list-row {
         border-radius: 2px;
         cursor: pointer;
         display: block;
         display: flex;
         font-size: 12px;
         height: 55px;
         justify-content: space-between;
         margin-bottom: 8px;
         vertical-align: middle;
         align-items: center;
    }
     body.dark .coin-list-row {
         border-radius: 2px;
         cursor: pointer;
         display: block;
         display: flex;
         font-size: 12px;
         height: 55px;
         justify-content: space-between;
         margin-bottom: 8px;
         vertical-align: middle;
         align-items: center;
    }
     body.light .scrollHider {
         background-color: #e7e7e7;
         padding: 1px 0 0 0;
         margin: 1px 0 0 0;
    }
     body.dark .scrollHider {
         background-color: #2b2e39;
         padding: 1px 0 0 0;
         margin: 1px 0 0 0;
    }
     .scrollHider {
         align-items: center;
         background-color: inherit;
         display: flex !important;
         width: 100%;
         overflow-x: auto;
    }
     body.light .menuBox {
         background-color: #fff;
         margin: 1px;
         overflow-y: hidden;
    }
     body.dark .menuBox {
         background-color: #151823;
         margin: 1px;
         overflow-y: hidden;
    }
     .menuBox {
         direction: rtl !important;
         display: flex !important;
         white-space: nowrap;
         vertical-align: middle;
         align-items: center;
         flex-direction: row;
         height: 32px;
         width: 100%;
         overflow-y: hidden;
    }
     body.dark .menuBox * {
         padding: 10px 20px 9px 20px;
         color: #848484;
         font-size: 11px;
         width: 120px;
         text-align: center;
    }
     body.dark .menuBox .activetab-m {
         color: #fff;
         font-size: 11px;
         font-weight: 600;
         text-align: center;
    }
     body.light .menuBox * {
         padding: 10px 20px 9px 20px;
         color: #848484;
         font-size: 11px;
         width: fit-content;
         text-align: center;
    }
     body.light .menuBox .activetab-m {
         color: #000;
         font-size: 11px;
         font-weight: 600;
         text-align: center;
    }
     .menuBox * {
         display: inline-block;
         min-width: max-content;
         max-width: 100px;
    }
     .slider-slide5-bg {
         content: '';
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0;
         left: 0;
         z-index: 1;
         background-image: url('./images/sbg05.png');
         background-size: cover;
         background-repeat: no-repeat no-repeat;
         background-position: top;
         opacity: 1
    }
     .slider-slide3-bg {
         content: '';
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0;
         left: 0;
         z-index: 1;
         background-image: url('./images/sbg04.png');
         background-size: cover;
         background-repeat: no-repeat no-repeat;
    }
     .slider-slide4-bg {
         content: '';
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0;
         left: 0;
         z-index: 1;
         background: url('./images/sbg05.png');
         background-size: cover;
         background-repeat: no-repeat no-repeat;
         background-position: center;
         opacity: 1
    }
     .slider-slide1 {
         opacity: 1;
         width: calc(100%);
         height: 550px;
         z-index: 2;
         position: inherit;
         font-family: shabnam !important
    }
     .slider-slide4 {
         opacity: 1;
         width: calc(100%);
         height: 550px;
         z-index: 2;
         position: inherit;
         font-family: shabnam !important
    }
     .slider-slide3 {
         opacity: 1;
         width: calc(100%);
         z-index: 2;
         position: inherit;
         font-family: shabnam !important
    }
     .slider-slide2 {
         opacity: 1;
         width: calc(100%);
         height: 550px;
         z-index: 2;
         position: inherit;
         font-family: shabnam !important
    }
     .slide span {
         font-size: 9px;
         display: inline-block;
         margin-top: 20px
    }
     .control-dots {
         margin-top: 50px !important;
    }
     h6 {
         color: inherit;
         font-family: inherit;
         font-weight: 500;
         line-height: 1.2;
         font-size: 1rem
    }
     .carousel .slider-wrapper {
         height: 150px
    }
     body.dark .carousel .control-dots .dot {
         background-color: orange;
    }
     body.light p.slider-p {
         color: #fff;
         font-size: 11px;
         margin: 0 20px 0 10px
    }
     .carousel {
         border-radius: 10px
    }
     body.light .carousel .control-dots .dot {
         background-color: blue;
    }
     .control-dots .selected {
         background: #0f0f00;
         height: 3px !important;
         width: 10px !important;
    }
     .carousel .control-dots .dot {
         height: 3px;
         width: 3px;
         border-radius: 2px;
         box-shadow: none
    }
     .top-bar {
         height: 50px;
         display: flex;
         flex-direction: row;
         justify-content: flex-start;
         direction: rtl;
         text-align: right;
         font-family: cursive;
         align-items: center;
         vertical-align: middle;
         padding: 0 10px 0 10px;
    }
     body.light .xs-login-card {
         border: 1px solid var(--gray-1);
         border-radius: 15px;
    }
     .xs-login-card .right-login-col {
         text-align: right;
         width: 80%;
    }
     .badg-dark-signup {
         border-radius: 20px;
         display: flex;
         margin-top: 10px;
         width: 140px;
         height: 37px;
         padding: 10px
    }
     body.light .badg-dark-signup {
         background-color: #000;
         color: #fff
    }
     body.dark .badg-dark-signup {
         background-color: var(--orange);
    }
     .badg-light-green {
         background-color: #58e2c7;
         border-radius: 20px;
         color: #000 !important;
         display: flex;
         margin-top: 10px;
         width: 140px;
         height: 37px;
    }
     .xs-login-card {
         margin: 0px 10px 20px 10px !important;
         padding: 13px 13px !important;
         height: 152px;
    }
     html {
         font-family: -apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif;
    }
     body {
         padding-top: 50px;
         text-align: right;
         font-family: -apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif;
    }
     body.dark {
         background-color: #000518;
         color: #fff;
         overflow: auto;
         width: auto;
         overflow-y: auto;
         overflow-x: hidden !important
    }
     .top-row {
         position: fixed;
         top: 0;
         left: 0;
         right: 0
    }
     body.light .top-row {
         border-bottom: 1px solid var(--gray-1);
         background-color: #fff;
         z-index: 2
    }
     body.dark .top-row {
         border-bottom: 1px solid var(--gray-10);
         background-color: #000518;
         z-index: 2
    }
     body.dark .top-bar svg {
         color: var(--gray-1);
    }
     body.dark .top-bar .icon-round {
         color: var(--gray-1);
    }
     .top-bar .icon-round {
         height: 32px;
         margin: auto 5px auto 7px;
         align-self: center;
         align-content: center;
         cursor: pointer;
    }
     .top-bar .topbar-input {
         width: calc(100% - 130px);
         margin: 0 15px 0 15px;
    }
     body.dark .top-bar .topbar-input input {
         border: 1px solid #3a475d;
    }
     body.light .top-bar .topbar-input input {
         border: 1px solid var(--gray-1);
    }
     .top-bar .topbar-input input {
         width: 100%;
         height: 35px;
         font-size: 21px;
         text-align: left;
         padding: 0 0 0 10px;
         border-radius: 20px
    }
     .main-wrapper .sidebar{
         display: none !important
    }
     p{
         font-size: 10px
    }
     span{
         font-size: 10px
    }
     a{
         font-size: 10px
    }
     h6{
         font-size: 10px
    }
     body.dark .serach-magnifier {
         position: relative;
         display: block;
         top: -42px;
         right: 1px;
         margin-left: auto;
         padding: 10px 0px 12px 8px;
         width: 100px;
         color: white;
    }
     body.light .serach-magnifier {
         position: relative;
         display: block;
         top: -42px;
         right: 1px;
         margin-left: auto;
         padding: 10px 0px 12px 8px;
         background-color: #e7e7e7;
         border: 1px solid #e7e7e7;
         border-radius: 3px;
         width: 100px;
         color: 000;
    }
     .tx-modal .modal-content{
         height: 100% !important;
    }
     .modal-dialog.tx-modal{
         left: 0;
         height: 100%;
         position: fixed;
         top: 0;
         margin-top: 0 !important
    }
     .market-list-modal{
         height: 500px;
         overflow: auto;
    }
     .tx-modal .modal-header .icon-close{
         margin-left: auto;
    }
     .tx-modal .modal-header{
         justify-content: flex-end;
         position: fixed;
         top: 0
    }
     input.market-search-input{
         width: 400px;
         border: 1px solid #e7e7e740 !important;
    }
}
 @media (min-width: 768px)and (max-width: 991px) {
     body.dark .toright-collapse .sticky-top{
         background-color: #181b26;
         padding-bottom: 50px;
         top:-10px
    }
     body.dark .chart-xl-bottom{
         height: 400px;
         background-color: #181b26
    }
     body.dark .bm-menu-wrap{
         background-color: #000518
    }
     body.light .bm-menu-wrap{
         background-color: #fff
    }
     .o-l-c{
         background-color: #181b26
    }
     .col-left{
         background-color: #181b26
    }
     .tx-modal .modal-content{
         height: 100% !important;
    }
     .modal-dialog.tx-modal{
         left: 0;
         height: 100%;
         position: fixed;
         top: 0;
         margin-top: 0 !important
    }
     .market-list-modal{
         height: 500px;
         overflow: auto;
    }
     .tx-modal .modal-header .icon-close{
         margin-left: auto;
    }
     .tx-modal .modal-header{
         justify-content: flex-end;
    }
     input.market-search-input{
         width: 400px;
         border: 1px solid #e7e7e740 !important;
    }
     body.dark .serach-magnifier {
         position: relative;
         display: block;
         top: -42px;
         right: 1px;
         margin-left: auto;
         padding: 10px 0px 12px 8px;
         width: 100px;
         color: white;
    }
     body.light .serach-magnifier {
         position: relative;
         display: block;
         top: -42px;
         right: 1px;
         margin-left: auto;
         padding: 10px 0px 12px 8px;
         background-color: #e7e7e7;
         border: 1px solid #e7e7e7;
         border-radius: 3px;
         width: 100px;
         color: 000;
    }
     body.light .toright-collapse .sticky-top{
         background-color: #fff;
         padding-bottom: 50px;
         top:-10px
    }
     p{
         font-size: 10px
    }
     span{
         font-size: 10px
    }
     a{
         font-size: 10px
    }
     h6{
         font-size: 10px
    }
     .main-wrapper {
         transition: width, left, right, 300ms;
    }
     .main-wrapper-full {
         margin-right: 130px;
         padding-right: 20px;
         padding-top: 70px;
         padding-left: 20px;
         margin-left: 50px;
         transition: width, left, right, 300ms;
    }
     .orderform-collapse{
         display: none;
    }
     .market-bottom{
         display: none
    }
     #home04{
         display: none !important
    }
     body.dark .input-group.rtl .btn {
         width: 200px;
         border:1px solid #495057 !important;
         border-left: none !important;
         border-top-left-radius: 0px !important;
         border-bottom-left-radius: 0px !important;
         border-top-right-radius: 3px !important;
         border-bottom-right-radius: 3px !important;
         height: 50px;
         background-color: #000519
    }
     .crypto-td-7{
         display: none
    }
     body.dark .main-wrapper {
         padding: 15px 10px 50px 10px
    }
     body.light .main-wrapper {
         background-color: #fff;
         padding: 15px 10px 50px 10px
    }
     .react-switch-handle{
         margin-top: 3px
    }
     body.light .col-left{
         background-color: #fff
    }
     body.light .o-l-c{
         background-color: #fff
    }
     body.light .mc-header{
         background-color: #fff
    }
     body.light .xl-chart-bottom{
         background-color: #fff
    }
     .col-left{
    }
     body.dark .market-sidebar {
         background-color: #161924;
         color: #fff;
         height: 100%;
         width: 250px;
         position: fixed;
         top: 0;
         right: 0;
         z-index: 3;
    }
     body.light .market-sidebar {
         background-color: #fff;
         color: #161924;
         height: 100%;
         width: 250px;
         position: fixed;
         top: 0;
         right: 0;
         z-index: 3;
    }
     .top-navbar-md{
         display: none !important
    }
     .top-navbar-md .container-fluid .landing-nav{
         margin: 0 10px 0 20px;
    }
     .top-navbar-md .container-fluid .login-button{
         margin-right: 0 auto 0 20px;
    }
     body.dark .top-navbar-md .container-fluid{
         display: none;
    }
     body.light .top-navbar-md .container-fluid{
         display: flex;
         position: fixed;
         vertical-align: middle;
         align-items: center;
         flex-direction: row;
         flex: auto;
         direction: rtl;
         top:0;
         height: 50px;
         left: 0;
         right: 0;
         z-index: 9;
         background-color: #fff;
         border-bottom : 1px solid #fff;
         justify-content: space-between;
    }
     body {
         font-size: 11px
    }
     .d-md-block{
         display: block !important
    }
     .xs-overlay .menuSection p {
         width: max-content
    }
     .xs-overlay .menuSection {
         display: grid !important;
         width: max-content;
         padding: 0 0 0 30px;
         display: inline-block;
         margin: 0 0 0 20px;
    }
     .xs-screen {
         border: 1px solid #efefef50;
         border-radius: 15px
    }
     .xs-overlay {
         display: flex;
         overflow: auto;
         vertical-align: middle;
         height: 80px;
         align-items: center;
         overflow-x: auto;
    }
     .no-gutters {
         padding: 0 !important
    }
     body.dark .category-badge {
         font-size: 10px;
         width: 60px;
         margin: 0 10px 0 10px;
         cursor: pointer;
         display: table-cell;
         text-align: center;
         padding: 5px 5px 5px 5px;
         border: 1px solid #9292921f;
         border-radius: 5px;
         background-color: #13161f;
    }
     body.dark .active-category-badge {
         font-size: 10px;
         width: 60px;
         margin: 0 10px 0 10px;
         cursor: pointer;
         display: table-cell;
         text-align: center;
         padding: 5px 5px 5px 5px;
         border: 1px solid #9292921f;
         border-radius: 5px;
         color: #fff;
         background-color: var(--mint);
    }
     body.light .category-badge {
         font-size: 10px;
         width: 60px;
         margin: 0 10px 0 10px;
         cursor: pointer;
         display: table-cell;
         text-align: center;
         padding: 5px 5px 5px 5px;
         border: 1px solid #9292921f;
         border-radius: 5px;
         background-color: #b1b1b1;
         color: #000
    }
     body.light .active-category-badge {
         font-size: 10px;
         width: 60px;
         margin: 0 10px 0 10px;
         cursor: pointer;
         display: table-cell;
         text-align: center;
         padding: 5px 5px 5px 5px;
         border: 1px solid #9292921f;
         border-radius: 5px;
         color: #000;
         background-color: var(--mint);
    }
     body.dark .form-text {
         color: #d0d4dc;
         font-size: 10px;
    }
     body.light .form-text {
         color: #d0d4dc;
         font-size: 10px;
    }
     .switch-side-btn.sell .btntextsell {
         position: absolute;
         right: 61% !important;
         margin-top: 5px;
         z-index: 1099;
         color: #fff
    }
     .market-layer .btn-danger {
         background-color: var(--neon);
         border: 1px solid var(--neon);
    }
     .market-layer .btn-success {
         background-color: var(--mint);
         border: 1px solid var(--mint);
    }
     .switch-side-btn.buy .btntextbuy {
         position: absolute;
         right: 32% !important;
         margin-top: 5px;
         z-index: 1099;
         color: #fff
    }
     .switch-side-btn.sell::after {
         content: '';
         width: 60%;
         position: fixed;
         height: 35px;
         border-radius: 30px;
         background-color: var(--neon);
         left: 26px;
         text-align: center;
         border: 1px solid var(--neon);
         margin-top: -1px
    }
     .switch-side-btn.buy::after {
         content: '';
         width: 60%;
         position: fixed;
         height: 35px;
         border-radius: 30px;
         background-color: var(--mint);
         right: 26px;
         text-align: center;
         border: 1px solid var(--mint);
         margin-top: -1px
    }
     .switch-side-btn {
         margin: 10px 20px 10px 20px;
         height: 35px;
         border-radius: 30px;
         background-color: #3a3333;
         border: 1px solid #75757520;
    }
     .orderform-collapse .closebtn {
         position: fixed;
         left: 15px;
         top: 15px
    }
     body.dark .market-layer input.market-input {
         background-color: #181b26;
         margin: 10px 0 10px 0;
         border: 1px solid 393939;
         height: 36px;
         font-size: 11px;
         text-align: right;
         direction: rtl;
         padding: 0 10px 0 20px
    }
     body.light .market-layer input.market-input {
         background-color: #fff;
         margin: 10px 0 10px 0;
         border: 1px solid 393939;
         height: 36px;
         font-size: 11px;
         text-align: right;
         direction: rtl;
         padding: 0 10px 0 20px
    }
     .market-input-symbol {
         background-color: #fff0;
         color: #757575 !important;
         cursor: pointer;
         display: block;
         font-size: 14px;
         font-size: 10px;
         height: 17px;
         left: 12px;
         margin-top: -38px;
         padding: 3px;
         position: absolute;
         right: auto;
         text-align: right;
         z-index: 2;
    }
     body.dark .orderform-collapse {
         background-color: #181b26;
         position: fixed;
         bottom: 56px;
         width: 100%;
         padding: 10px 5px 10px 5px;
         color: gray;
         min-height: 500px
    }
     body.light .orderform-collapse {
         background-color: #fff;
         position: fixed;
         bottom: 56px;
         width: 100%;
         padding: 10px 5px 10px 5px;
         color: #000;
         min-height: 500px
    }
     body.dark .toright-collapse {
         background-color: #181b26;
         position: fixed;
         bottom: 56px;
         width: 100%;
         padding: 10px 5px 10px 5px;
         color: gray;
         height: 100%;
         overflow: auto;
         z-index: 8;
         padding: 60px 0 50px 0
    }
     body.light .toright-collapse {
         background-color: #fff;
         position: fixed;
         bottom: 56px;
         width: 100%;
         padding: 10px 5px 10px 5px;
         color: gray;
         height: 100%;
         overflow: auto;
         z-index: 8;
         padding: 60px 0 50px 0
    }
     .toright-collapse-row .symbol-row {
         min-width: 100px !important;
         font-size: 11px;
         text-align: left;
    }
     body.dark .symbol-row.slug {
         min-width: 100px !important;
         font-size: 12px;
         text-align: left;
         font-weight: bold;
         color: #fff
    }
     body.light .symbol-row.slug {
         min-width: 100px !important;
         font-size: 12px;
         text-align: left;
         font-weight: bold;
         color: #000
    }
     .symbol-row.icon {
         min-width: 50px !important;
         font-size: 12px;
         text-align: left;
         font-weight: bold;
         color: #fff
    }
     .toright-collapse-row {
         justify-content: space-between;
    }
     .toright-collapse input {
         text-align: left !important;
         direction: ltr !important;
         font-size: 14px !important;
         margin: 10px !important
    }
     .toright-collapse-row.header {
         justify-content: space-between;
         border-bottom: 1px solid #232731;
         margin: 20px 0 20px 50px;
         padding-bottom: 10px
    }
     .order-form-row:last-child {
         margin-right: auto;
    }
     .order-form-row {
         direction: rtl;
         display: flex;
         justify-content: flex-start;
         padding: 10px
    }
     body.dark .market-layer {
         background-color: #181b26;
         height: 100%;
    }
     .mc-header-2 {
         height: 23px;
         padding: 5px;
         display: flex;
         flex-direction: row;
    }
     .asks:first-child .text-success {
         color: #06bc8d;
         vertical-align: middle;
         margin: auto auto 0 0;
         align-self: center;
         display: block;
         padding: 5px
    }
     .market-layer .col-md-4{
         padding: 0 5px 0 0
    }
     .market-layer .col-md-8{
         padding: 0 5px 0 15px !important;
    }
     .asks:first-child p {
         vertical-align: middle;
         align-self: center;
         display: block;
         padding: 5px
    }
     body.dark .mc-header-3 {
         height: 32px;
         text-align: center;
         background-color: #2b2e39;
         padding: 1px 0 1px 0;
    }
     body.dark .mc-header-3 p {
         margin: 1px;
         padding: 7px 0 5px 0;
         background-color: #181b26;
         height: 28px
    }
     body.light .mc-header-3 {
         height: 32px;
         text-align: center;
         background-color: #e7e7e7;
         padding: 1px 0 1px 0;
    }
     body.light .mc-header-3 p {
         margin: 1px;
         padding: 5px 0 5px 0;
         background-color: #fff
    }
     .bids .text-danger {
         color: #f56754;
         vertical-align: middle;
         margin: auto auto 0 0;
         align-self: center;
         display: block;
         padding: 5px
    }
     .bids p {
         vertical-align: middle;
         align-self: center;
         display: block;
         padding: 5px;
    }
     .asks:first-child {
         padding: 10px;
         display: flex;
         justify-content: space-between;
         vertical-align: middle;
    }
     .asks {
         height: 245px;
         overflow-y: auto;
         display: flex;
         flex-direction: column-reverse !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
    }
     #ask {
         height: 100%;
         overflow-y: auto;
         display: flex;
         flex-direction: column !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
         line-height: 24px
    }
     #bid {
         height: 100%;
         overflow-y: auto;
         display: flex;
         flex-direction: column !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
         line-height: 24px
    }
     .bids {
         height: 245px;
         overflow-y: auto;
         display: flex;
         flex-direction: column !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
         padding: 5px
    }
     #ask-bid {
         height: 400px
    }
     .sidebar {
         height: 100%;
         width: 250px;
         position: fixed !important;
         top: 50;
         right: 0;
         z-index: 3;
         background-color: #000518
    }
     .ps-sidebar-container{
         background-color: #000518 !important
    }
     .ps-sidebar-root {
         border: none !important
    }
     .mc-nav {
         display: flex;
         height: 50px;
         position: fixed;
         top: 0;
         width: 100%;
         background-color: #0d0f14;
         z-index: 5;
         justify-content: space-between;
         padding: 0 10px 0 10px;
         vertical-align: middle;
         align-items: center;
         color: #fff;
         font-size: 13px;
         min-width: max-content
    }
     .d-grid {
         display: grid !important;
    }
     .mc-nav .icon-medium {
         display: block;
         height: 24px !important;
         margin-top: 6px;
         position: absolute;
         width: 24px !important;
    }
     .mc-nav .slug-header {
         margin: 7px 30px auto 30px;
         vertical-align: middle;
         border-bottom: 1px dotted #e7e7e7;
    }
     .mc-nav {
         display: none
    }
     .deposit-btn {
         border: 1px solid #28b0df;
         border-radius: 30px;
         display: flex;
         margin: auto 0 0 auto;
         padding: 0 10px;
    }
     .mc-nav .mc-nav-item {
         display: flex;
    }
     .mc-nav-xs {
         display: none;
    }
     .chart-xl {
         height: 300px
    }
     .main-auth {
         padding-bottom: 100px
    }
     .main-auth .btn {
         height: 50px
    }
     body.dark ::marker {
         color: var(--orange);
    }
     body.dark {
         input:-webkit-autofill, input:-webkit-autofill:active, input:-webkit-autofill:focus, input:-webkit-autofill:hover {
             -webkit-text-fill-color: #fff;
             -webkit-background-clip: text;
             box-shadow: inset 0 0 20px 20px #23232329;
        }
    }
     body.light {
         input:-webkit-autofill, input:-webkit-autofill:active, input:-webkit-autofill:focus, input:-webkit-autofill:hover {
             -webkit-text-fill-color: #000518;
             -webkit-background-clip: text;
             box-shadow: inset 0 0 20px 20px #fff;
        }
    }
     body.dark input {
         background-color: #000518;
         color: #fff;
         width: 100%;
         height: 50px;
         border: 1px solid #232731;
         border-radius: 3px
    }
     body.light input {
         background-color: #fff;
         color: #000;
         width: 100%;
         height: 50px;
         border: 1px solid var(--gray-1);
         border-radius: 3px
    }
     .form-label {
         margin-bottom: 20px;
         margin-right: 20px;
         display: list-item;
    }
     .btn {
         width: 200px;
         display: block;
         text-align: center;
         align-items: center;
         vertical-align: middle;
         padding: 10px 0 10px 0;
         border-radius: 5px;
    }
     .xs-navbar .container-fluid {
         display: flex;
         flex-direction: row;
         float: right;
         justify-content: space-between;
         font-size: 10px
    }
     .xs-navbar {
         background-color: #000518;
         bottom: 0px !important;
         color: #fff;
         position: fixed !important;
         width: 100%;
         z-index: 50;
         padding: 10px;
    }
     body.dark .coin-list-row, body.light .coin-list-row {
         background-color: #1c1c39
    }
     body.light .coin-list-row, body.light .coin-list-row {
         background-color: #e5e5ff
    }
     body.light .coin-list-row {
         border-radius: 2px;
         cursor: pointer;
         display: block;
         display: flex;
         font-size: 12px;
         height: 55px;
         justify-content: space-between;
         margin-bottom: 8px;
         vertical-align: middle;
         align-items: center;
    }
     body.dark .coin-list-row {
         border-radius: 2px;
         cursor: pointer;
         display: block;
         display: flex;
         font-size: 12px;
         height: 55px;
         justify-content: space-between;
         margin-bottom: 8px;
         vertical-align: middle;
         align-items: center;
    }
     body.light .scrollHider {
         background-color: #e7e7e7;
         padding: 1px 0 0 0;
         margin: 1px 0 0 0;
    }
     body.dark .scrollHider {
         background-color: #2b2e39;
         padding: 1px 0 0 0;
         margin: 1px 0 0 0;
    }
     .scrollHider {
         align-items: center;
         background-color: inherit;
         display: flex !important;
         width: 100%;
         overflow-x: auto;
    }
     body.light .menuBox {
         background-color: #fff;
         margin: 1px;
         overflow-y: hidden;
    }
     body.dark .menuBox {
         background-color: #151823;
         margin: 1px;
         overflow-y: hidden;
    }
     .menuBox {
         direction: rtl !important;
         display: flex !important;
         white-space: nowrap;
         vertical-align: middle;
         align-items: center;
         flex-direction: row;
         height: 32px;
         width: 100%;
         overflow-y: hidden;
    }
     body.dark .menuBox * {
         padding: 10px 20px 9px 20px;
         color: #848484;
         font-size: 11px;
         width: 120px;
         text-align: center;
    }
     body.dark .menuBox .activetab-m {
         color: #fff;
         font-size: 11px;
         font-weight: 600;
         text-align: center;
    }
     body.light .menuBox * {
         padding: 10px 20px 9px 20px;
         color: #848484;
         font-size: 11px;
         width: fit-content;
         text-align: center;
    }
     body.light .menuBox .activetab-m {
         color: #000;
         font-size: 11px;
         font-weight: 600;
         text-align: center;
    }
     .menuBox * {
         display: inline-block;
         min-width: max-content;
         max-width: 100px;
    }
     .slider-slide5-bg {
         content: '';
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0;
         left: 0;
         z-index: 1;
         background-image: url('./images/sbg05.png');
         background-size: cover;
         background-repeat: no-repeat no-repeat;
         background-position: top;
         opacity: 1
    }
     .slider-slide3-bg {
         content: '';
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0;
         left: 0;
         z-index: 1;
         background-image: url('./images/sbg04.png');
         background-size: cover;
         background-repeat: no-repeat no-repeat;
    }
     .slider-slide4-bg {
         content: '';
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0;
         left: 0;
         z-index: 1;
         background: url('./images/sbg05.png');
         background-size: cover;
         background-repeat: no-repeat no-repeat;
         background-position: center;
         opacity: 1
    }
     .slider-slide1 {
         opacity: 1;
         width: calc(100%);
         height: 550px;
         z-index: 2;
         position: inherit;
         font-family: shabnam !important
    }
     .slider-slide4 {
         opacity: 1;
         width: calc(100%);
         height: 550px;
         z-index: 2;
         position: inherit;
         font-family: shabnam !important
    }
     .slider-slide3 {
         opacity: 1;
         width: calc(100%);
         z-index: 2;
         position: inherit;
         font-family: shabnam !important
    }
     .slider-slide2 {
         opacity: 1;
         width: calc(100%);
         height: 550px;
         z-index: 2;
         position: inherit;
         font-family: shabnam !important
    }
     .slide span {
         font-size: 9px;
         display: inline-block;
         margin-top: 20px
    }
     .control-dots {
         margin-top: 50px !important;
    }
     h6 {
         color: inherit;
         font-family: inherit;
         font-weight: 500;
         line-height: 1.2;
         font-size: 1rem
    }
     .carousel .slider-wrapper {
         height: 150px
    }
     body.dark .carousel .control-dots .dot {
         background-color: orange;
    }
     body.light p.slider-p {
         color: #fff;
         font-size: 11px;
         margin: 0 20px 0 10px
    }
     .carousel {
         border-radius: 10px
    }
     body.light .carousel .control-dots .dot {
         background-color: blue;
    }
     .control-dots .selected {
         background: #0f0f00;
         height: 3px !important;
         width: 10px !important;
    }
     .carousel .control-dots .dot {
         height: 3px;
         width: 3px;
         border-radius: 2px;
         box-shadow: none
    }
     .top-bar {
         height: 50px;
         display: flex;
         flex-direction: row;
         justify-content: flex-start;
         direction: rtl;
         text-align: right;
         font-family: cursive;
         align-items: center;
         vertical-align: middle;
         padding: 0 10px 0 10px;
    }
     body.light .xs-login-card {
         border: 1px solid var(--gray-1);
         border-radius: 15px;
    }
     .xs-login-card .right-login-col {
         text-align: right;
         width: 80%;
    }
     .badg-dark-signup {
         border-radius: 20px;
         display: flex;
         margin-top: 10px;
         width: 140px;
         height: 37px;
         padding: 10px
    }
     body.light .badg-dark-signup {
         background-color: #000;
         color: #fff
    }
     body.dark .badg-dark-signup {
         background-color: var(--orange);
    }
     .badg-light-green {
         background-color: #58e2c7;
         border-radius: 20px;
         color: #000 !important;
         display: flex;
         margin-top: 10px;
         width: 140px;
         height: 37px;
    }
     .xs-login-card {
         margin: 0px 10px 20px 10px !important;
         padding: 13px 13px !important;
         height: 152px;
    }
     html {
         font-family: -apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif;
    }
     body {
         padding-top: 50px;
         text-align: right;
         font-family: -apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif;
    }
     body.dark {
         background-color: #000518;
         color: #fff;
         overflow: auto;
         width: auto;
         overflow-y: auto;
         overflow-x: hidden !important
    }
     .top-row {
         position: fixed;
         top: 0;
         left: 0;
         right: 0
    }
     body.light .top-row {
         border-bottom: 1px solid var(--gray-1);
         background-color: #fff;
         z-index: 2
    }
     body.dark .top-row {
         border-bottom: 1px solid var(--gray-10);
         background-color: #000518;
         z-index: 2
    }
     body.dark .top-bar svg {
         color: var(--gray-1);
    }
     body.dark .top-bar .icon-round {
         color: var(--gray-1);
    }
     .top-bar .icon-round {
         height: 32px;
         margin: auto 5px auto 7px;
         align-self: center;
         align-content: center;
         cursor: pointer;
    }
     .top-bar .topbar-input {
         width: calc(100% - 130px);
         margin: 0 15px 0 15px;
    }
     body.dark .top-bar .topbar-input input {
         border: 1px solid #3a475d;
    }
     body.light .top-bar .topbar-input input {
         border: 1px solid var(--gray-1);
    }
     .top-bar .topbar-input input {
         width: 100%;
         height: 35px;
         font-size: 21px;
         text-align: left;
         padding: 0 0 0 10px;
         border-radius: 20px
    }
     body.dark .market-sidebar {
         background-color: #000518;
         color: #fff;
         height: 100%;
         width: 250px;
         position: fixed;
         top: 0;
         right: 0;
         z-index: 3;
    }
     .market-layer .btn-light{
         padding: 10px
    }
     body.dark .serach-magnifier {
         position: relative;
         display: block;
         top: -42px;
         right: 1px;
         margin-left: auto;
         padding: 10px 0px 12px 8px;
         width: 100px;
         color: white;
    }
     body.light .serach-magnifier {
         position: relative;
         display: block;
         top: -42px;
         right: 1px;
         margin-left: auto;
         padding: 10px 0px 12px 8px;
         background-color: #e7e7e7;
         border: 1px solid #e7e7e7;
         border-radius: 3px;
         width: 100px;
         color: 000;
    }
}
 @media (min-width: 992px)and (max-width: 1199px) {
     .market-layer .btn-light{
         padding: 10px
    }
     body.dark .toright-collapse .sticky-top{
         background-color: #181b26;
         padding-bottom: 50px;
         top:-10px
    }
     .chart-xl-bottom{
         height: 400px;
    }
     body.light .toright-collapse .sticky-top{
         background-color: #fff;
         padding-bottom: 50px;
         top:-10px
    }
     .main-wrapper {
         margin-right: 260px;
         margin-left: 30px;
         padding-top: 50px;
         transition: width, left, right, 300ms;
    }
     .main-wrapper-full {
         margin-right: 130px;
         padding-right: 20px;
         padding-top: 70px;
         padding-left: 20px;
         margin-left: 50px;
         transition: width, left, right, 300ms;
    }
     .crypto-row{
         height: 50px;
    }
     .crypto-td-7{
         display: none !important
    }
     body.dark .market-bottom {
         display: none;
    }
     body.light .market-bottom {
         display: none;
    }
     .top-navbar-md .container-fluid .landing-nav{
         margin: 0 10px 0 10px;
    }
     .top-navbar-md .container-fluid .login-button{
         margin-right: 0 auto 0 20px;
    }
     .top-navbar-md .container-fluid{
         display: flex;
         position: fixed;
         vertical-align: middle;
         align-items: center;
         flex-direction: row;
         flex: auto;
         direction: rtl;
         top:0;
         height: 55px;
         left: 0;
         right: 0;
         z-index: 9;
         font-size: 12px;
         align-items: center;
    }
     body.dark .top-navbar-md .container-fluid{
         background-color: #000518;
         justify-content: space-between;
         height: 53px;
         flex-wrap: initial !important
    }
     body.light .top-navbar-md .container-fluid{
         background-color: #000518;
         justify-content: space-between;
         height: 53px;
         flex-wrap: initial !important
    }
     .exchange-section {
         border-radius: 20px;
         margin: 50px 20px;
         padding: 50px 20px;
    }
     body.light .top-navbar-md .container-fluid svg{
         color: #000 !important
    }
     body.light .top-navbar-md .container-fluid{
         background-color: #fdfbfb;
         justify-content: space-between;
         height: 53px;
    }
    /* body.dark .main-wrapper {
         background-color: #181b26;
         margin-right: 260px;
         padding: 50px 10px 70px 0
    }
     body.light .main-wrapper {
         background-color: #fdfbfb;
         margin-right: 260px;
         margin-left: 10px;
         padding: 55px 10px 70px 10px;
         font-size: 13px
    }
     */
     .top-navbar-md .container-fluid .landing-nav{
         margin: 0 10px 0 10px;
    }
     .top-navbar-md .container-fluid .login-button{
         margin-right: 0 auto 0 20px;
    }
     .top-row {
         display: none !important
    }
     .top-navbar-md{
         display: flex;
         z-index: 11
    }
     body.dark .logo-container-home {
         background: url('./images/mainlogo.png');
         background-repeat: no-repeat no-repeat;
         background-size: contain;
         width: 40px;
         height: 40px;
         margin-top: 8px;
         display: block;
         vertical-align: center;
    }
     body.light .logo-container-home {
         background: url('./images/mainlogo.png');
         background-repeat: no-repeat no-repeat;
         background-size: contain;
         width: 50px;
         height: 50px;
         margin-top: 8px;
         display: block;
         vertical-align: center;
    }
     .market-layer .btn-light {
         background-color: gray;
         width: 100%;
         height: 43px;
    }
     .market-layer .btn-block {
         width: 100% !important
    }
     .market-layer .col-lg-3 {
         padding: 0 10 0 0;
         margin-right: 0
    }
     #bid {
         height: 100%;
         overflow-y: auto;
         display: flex;
         flex-direction: column !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
         line-height: 24px;
         background-color: #fff
    }
     body.light .bids {
         height: 300px;
         overflow-y: auto;
         display: flex;
         flex-direction: column !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
         padding: 5px;
         background-color: #fff
    }
     body.dark .bids {
         height: 300px;
         overflow-y: auto;
         display: flex;
         flex-direction: column !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
         padding: 5px;
    }
     #ask-bid {
         height: 700px
    }
     body.light .asks {
         height: 300px;
         overflow-y: auto;
         display: flex;
         flex-direction: column-reverse !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
         background-color: #fff
    }
     body.dark .asks {
         height: 300px;
         overflow-y: auto;
         display: flex;
         flex-direction: column-reverse !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
    }
     #ask {
         height: 100%;
         overflow-y: auto;
         display: flex;
         flex-direction: column !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
         line-height: 24px
    }
     .market-layer .col-xl-3 {
         padding: 0 10 0 0;
    }
     body.dark .market-layer {
         background-color: #181b26;
    }
     body.light .market-layer {
         background-color: #fff;
         height: max-content;
    }
     body.light .mc-header-2 {
         background-color: #fff;
    }
     .mc-header-2 {
         height: 28px;
         padding: 5px 5px 20px 5px;
         display: flex;
         flex-direction: row;
         margin-bottom: 1px
    }
     body.light .place-order {
         margin: 1px;
         background-color: #fff !important;
         height: 430px
    }
     body.light .market-container {
         background-color: #e7e7e7;
    }
     body.light .o-l-c {
         margin: 1px;
         background-color: #e7e7e7
    }
     body.light .mc-nav {
         background-color: #e7e7e8;
         color: #000
    }
     body.dark .market-sidebar {
         background-color: #161924;
         color: #fff;
         height: 100%;
         width: 250px;
         position: fixed;
         top: 0;
         right: 0;
         z-index: 3;
    }
     body.light .market-sidebar {
         background-color: #fff;
         color: #161924;
         height: 100%;
         width: 250px;
         position: fixed;
         top: 0;
         right: 0;
         z-index: 3;
    }
     .market-layer .col-lg-9 {
         padding: 5px;
         margin: 0 0px 0 0px
    }
     .market-layer .col-xl-9 {
         padding: 0 5px 0 15px;
    }
     .no-gutter {
         padding: 0 !important
    }
     body.dark .table thead th {
         vertical-align: bottom;
         border-bottom: 1px solid #9292921f
    }
     body.light .table thead th {
         vertical-align: bottom;
         border-bottom: 1px solid #9292921f
    }
     body.dark .category-badge {
         font-size: 10px;
         width: 60px;
         margin: 0 10px 0 10px;
         cursor: pointer;
         display: table-cell;
         text-align: center;
         padding: 5px 5px 5px 5px;
         border: 1px solid #9292921f;
         border-radius: 5px;
         background-color: #13161f;
    }
     body.dark .active-category-badge {
         font-size: 10px;
         width: 60px;
         margin: 0 10px 0 10px;
         cursor: pointer;
         display: table-cell;
         text-align: center;
         padding: 5px 5px 5px 5px;
         border: 1px solid #9292921f;
         border-radius: 5px;
         color: #fff;
         background-color: var(--mint);
    }
     body.light .category-badge {
         font-size: 10px;
         width: 60px;
         margin: 0 10px 0 10px;
         cursor: pointer;
         display: table-cell;
         text-align: center;
         padding: 5px 5px 5px 5px;
         border: 1px solid #9292921f;
         border-radius: 5px;
         background-color: #b1b1b1;
         color: #000
    }
     body.light .active-category-badge {
         font-size: 10px;
         width: 60px;
         margin: 0 10px 0 10px;
         cursor: pointer;
         display: table-cell;
         text-align: center;
         padding: 5px 5px 5px 5px;
         border: 1px solid #9292921f;
         border-radius: 5px;
         color: #000;
         background-color: var(--mint);
    }
     body.dark .form-text {
         color: #d0d4dc;
         font-size: 10px;
    }
     body.light .form-text {
         color: #000;
         font-size: 10px;
    }
     .switch-side-btn.sell .btntextsell {
         position: absolute;
         right: 61% !important;
         margin-top: 5px;
         z-index: 1099;
         color: #fff
    }
     .market-layer .btn-danger {
         background-color: var(--neon);
         border: 1px solid var(--neon);
    }
     .market-layer .btn-success {
         background-color: var(--mint);
         border: 1px solid var(--mint);
    }
     .switch-side-btn.buy .btntextbuy {
         position: absolute;
         right: 32% !important;
         margin-top: 5px;
         z-index: 1099;
         color: #fff
    }
     .switch-side-btn.sell::after {
         content: '';
         width: 60%;
         position: fixed;
         height: 35px;
         border-radius: 30px;
         background-color: var(--neon);
         left: 26px;
         text-align: center;
         border: 1px solid var(--neon);
         margin-top: -1px
    }
     .switch-side-btn.buy::after {
         content: '';
         width: 60%;
         position: fixed;
         height: 35px;
         border-radius: 30px;
         background-color: var(--mint);
         right: 26px;
         text-align: center;
         border: 1px solid var(--mint);
         margin-top: -1px
    }
     .switch-side-btn {
         margin: 10px 20px 10px 20px;
         height: 35px;
         border-radius: 30px;
         background-color: #3a3333;
         border: 1px solid #75757520;
    }
     .orderform-collapse .closebtn {
         position: fixed;
         left: 15px;
         top: 15px
    }
     body.dark .market-layer input.market-input {
         background-color: #181b26;
         margin: 10px 0 10px 0;
         border: 1px solid 393939;
         height: 36px;
         font-size: 11px;
         text-align: right;
         direction: rtl;
         padding: 0 10px 0 20px;
         width: inherit;
    }
     body.light .market-layer input.market-input {
         background-color: #fff;
         margin: 10px 0 10px 0;
         border: 1px solid 393939;
         height: 36px;
         font-size: 11px;
         text-align: right;
         direction: rtl;
         padding: 0 10px 0 20px
    }
     .market-input-symbol {
         background-color: #fff0;
         color: #757575 !important;
         cursor: pointer;
         display: block;
         font-size: 14px;
         font-size: 10px;
         height: 17px;
         left: 12px;
         margin-top: -36px;
         padding: 3px;
         position: absolute;
         right: auto;
         text-align: right;
         z-index: 2;
    }
     .orderform-collapse{
         display: none
    }
     body.dark .toright-collapse {
         background-color: #181b26;
         position: fixed;
         bottom: 56px;
         width: 500px;
         padding: 10px 5px 10px 5px;
         color: gray;
         height: 100%;
         overflow: auto;
         z-index: 8;
         top:50px;
         border-radius: 10px;
         border: 1px solid #fafafa20;
         padding: 0px 0 50px 0
    }
     body.light .toright-collapse {
         background-color: #fff;
         position: fixed;
         bottom: 56px;
         width: 100%;
         padding: 10px 5px 10px 5px;
         color: gray;
         height: 100%;
         overflow: auto;
         z-index: 8;
         top:50px;
         padding: 60px 0 50px 0
    }
     .toright-collapse-row .symbol-row {
         min-width: 100px !important;
         font-size: 11px;
         text-align: left;
    }
     body.dark .symbol-row.slug {
         min-width: 100px !important;
         font-size: 12px;
         text-align: left;
         font-weight: bold;
         color: #fff
    }
     body.light .symbol-row.slug {
         min-width: 100px !important;
         font-size: 12px;
         text-align: left;
         font-weight: bold;
         color: #000
    }
     .symbol-row.icon {
         min-width: 50px !important;
         font-size: 12px;
         text-align: left;
         font-weight: bold;
         color: #fff
    }
     .toright-collapse-row {
         justify-content: space-between;
    }
     .toright-collapse input {
         text-align: left !important;
         direction: ltr !important;
         font-size: 14px !important;
         margin: 10px !important
    }
     .toright-collapse-row.header {
         justify-content: space-between;
         border-bottom: 1px solid #232731;
         margin: 20px 0 20px 50px;
         padding-bottom: 10px
    }
     .order-form-row:last-child {
         margin-right: auto;
    }
     .order-form-row {
         direction: rtl;
         display: flex;
         justify-content: flex-start;
         padding: 10px
    }
     .market-bottom .btn {
         border-radius: 25px;
         height: 40px;
         margin-top: 10px
    }
     .asks:first-child .text-success {
         color: #06bc8d;
         vertical-align: middle;
         margin: auto auto 0 0;
         align-self: center;
         display: block;
         padding: 5px
    }
     .asks:first-child p {
         vertical-align: middle;
         align-self: center;
         display: block;
         padding: 5px
    }
     body.dark .mc-header-3 {
         height: 32px;
         text-align: center;
         background-color: #2b2e39;
         padding: 1px 0 1px 0;
    }
     body.dark .mc-header-3 p {
         margin: 1px;
         padding: 5px 0 5px 0;
         background-color: #181b26;
         height: 28px
    }
     body.light .mc-header-3 {
         height: 32px;
         text-align: center;
         background-color: #e7e7e7;
         padding: 1px 0 1px 0;
    }
     body.light .mc-header-3 p {
         margin: 1px;
         padding: 5px 0 5px 0;
         background-color: #fff
    }
     .bids .text-danger {
         color: #f56754;
         vertical-align: middle;
         margin: auto auto 0 0;
         align-self: center;
         display: block;
         padding: 5px
    }
     .bids p {
         vertical-align: middle;
         margin: auto 0 0 auto;
         align-self: center;
         display: block;
         padding: 5px
    }
     .asks:first-child {
         padding: 10px;
         display: flex;
         justify-content: space-between;
         vertical-align: middle;
    }
     .sidebar {
         height: 100%;
         width: 250px;
         position: fixed;
         top: 0;
         right: 0;
         z-index: 3;
         background-color: #000518
    }
     .ps-sidebar-root {
         border: none !important
    }
     .list-inline{
         height: 43px
    }
     .mc-nav {
         display: flex;
         height: 53px;
         position: fixed;
         top: 0;
         width: 100%;
         background-color: #0d0f14;
         z-index: 5;
         justify-content: space-between;
         padding: 0 10px 0 10px;
         vertical-align: middle;
         align-items: center;
         color: #fff;
         font-size: 13px;
         min-width: max-content
    }
     .d-grid {
         display: grid !important;
    }
     .mc-nav .icon-medium {
         display: block;
         height: 24px !important;
         margin-top: 6px;
         position: absolute;
         width: 24px !important;
    }
     .mc-nav .slug-header {
                 margin: -2px 30px auto 51px;
         vertical-align: middle;
         border-bottom: 1px dotted #e7e7e7;
    }
     .mc-nav {
         .market-search {
             border-radius: 10px;
             font-size: 16px;
             height: 38px;
             padding-left: 10px;
             right: 0;
             width: 230px;
        }
    }
     .deposit-btn {
         border: 1px solid #28b0df;
         border-radius: 30px;
         display: flex;
         margin: auto 0 0 auto;
         padding: 0 10px;
    }
     .login-button {
         border-radius: 30px;
         display: flex;
         margin: auto 0 0 auto;
         padding: 0 10px;
    }
     .mc-nav .mc-nav-item {
         display: flex;
    }
     .mc-nav-xs {
         display: none;
    }
     .chart-xl {
         height: 300px
    }
     .main-auth {
         padding-bottom: 100px
    }
     .main-auth .btn {
         height: 50px
    }
     body.dark ::marker {
         color: var(--orange);
    }
     body.dark {
         input:-webkit-autofill, input:-webkit-autofill:active, input:-webkit-autofill:focus, input:-webkit-autofill:hover {
             -webkit-text-fill-color: #fff;
             -webkit-background-clip: text;
             box-shadow: inset 0 0 20px 20px #23232329;
        }
    }
     body.light {
         input:-webkit-autofill, input:-webkit-autofill:active, input:-webkit-autofill:focus, input:-webkit-autofill:hover {
             -webkit-text-fill-color: #000518;
             -webkit-background-clip: text;
             box-shadow: inset 0 0 20px 20px #fff;
        }
    }
     body.dark input {
         background-color: #000518;
         color: #fff;
         width: 100%;
         border: 1px solid #232731;
         border-radius: 3px
    }
     body.light input {
         background-color: #fff;
         color: #000;
         width: 100%;
         border: 1px solid var(--gray-1);
         border-radius: 3px
    }
     .form-label {
         margin-bottom: 20px;
         margin-right: 20px;
         display: list-item;
    }
     .signup-icon {
         padding: 0 20px 0 20px;
    }
     .xs-navbar .container-fluid {
         display: flex;
         flex-direction: row;
         float: right;
         justify-content: space-between;
         font-size: 10px
    }
     .xs-navbar {
         background-color: #000518;
         bottom: 0px !important;
         color: #fff;
         position: fixed !important;
         width: 100%;
         z-index: 50;
         padding: 10px;
    }
     body.dark .coin-list-row, body.light .coin-list-row {
         background-color: #1c1c39
    }
     body.light .coin-list-row, body.light .coin-list-row {
         background-color: #e5e5ff
    }
     body.light .coin-list-row {
         border-radius: 2px;
         cursor: pointer;
         display: block;
         display: flex;
         font-size: 12px;
         height: 55px;
         justify-content: space-between;
         margin-bottom: 8px;
         vertical-align: middle;
         align-items: center;
    }
     body.dark .coin-list-row {
         border-radius: 2px;
         cursor: pointer;
         display: block;
         display: flex;
         font-size: 12px;
         height: 55px;
         justify-content: space-between;
         margin-bottom: 8px;
         vertical-align: middle;
         align-items: center;
    }
     body.light .scrollHider {
         background-color: #e7e7e7;
         padding: 1px 0 0 0;
         margin: 1px 0 0 0;
    }
     body.dark .scrollHider {
         background-color: #2b2e39;
         padding: 1px 0 0 0;
         margin: 1px 0 0 0;
    }
     .scrollHider {
         align-items: center;
         background-color: inherit;
         display: flex !important;
         width: 100%;
         overflow-x: auto;
    }
     body.light .menuBox {
         background-color: #fff;
         margin: 1px;
         overflow-y: hidden;
    }
     body.dark .menuBox {
         background-color: #151823;
         margin: 1px;
         overflow-y: hidden;
    }
     .menuBox {
         direction: rtl !important;
         display: flex !important;
         white-space: nowrap;
         vertical-align: middle;
         align-items: center;
         flex-direction: row;
         height: 32px;
         width: 100%;
         overflow-y: hidden;
    }
     body.dark .menuBox * {
         padding: 10px 20px 9px 20px;
         color: #848484;
         font-size: 11px;
         text-align: center;
    }
     body.dark .menuBox .activetab-m {
         color: #fff;
         font-size: 11px;
         font-weight: 600;
         text-align: center;
    }
     body.light .menuBox * {
         padding: 10px 20px 9px 20px;
         color: #848484;
         font-size: 11px;
         width: fit-content;
         text-align: center;
    }
     body.light .menuBox .activetab-m {
         color: #000;
         font-size: 11px;
         font-weight: 600;
         text-align: center;
    }
     .menuBox * {
         display: inline-block;
         min-width: max-content;
         max-width: 100px;
    }
     .slider-slide5-bg {
         content: '';
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0;
         left: 0;
         z-index: 1;
         background-image: url('./images/sbg05.png');
         background-size: cover;
         background-repeat: no-repeat no-repeat;
         background-position: top;
         opacity: 1
    }
     .slider-slide3-bg {
         content: '';
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0;
         left: 0;
         z-index: 1;
         background-image: url('./images/sbg04.png');
         background-size: cover;
         background-repeat: no-repeat no-repeat;
    }
     .slider-slide4-bg {
         content: '';
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0;
         left: 0;
         z-index: 1;
         background: url('./images/sbg05.png');
         background-size: cover;
         background-repeat: no-repeat no-repeat;
         background-position: center;
         opacity: 1
    }
     .slider-slide1 {
         opacity: 1;
         width: calc(100%);
         height: 550px;
         z-index: 2;
         position: inherit;
         font-family: shabnam !important
    }
     .slider-slide4 {
         opacity: 1;
         width: calc(100%);
         height: 550px;
         z-index: 2;
         position: inherit;
         font-family: shabnam !important
    }
     .slider-slide3 {
         opacity: 1;
         width: calc(100%);
         z-index: 2;
         position: inherit;
         font-family: shabnam !important
    }
     .slider-slide2 {
         opacity: 1;
         width: calc(100%);
         height: 550px;
         z-index: 2;
         position: inherit;
         font-family: shabnam !important
    }
     .slide span {
         font-size: 9px;
         display: inline-block;
         margin-top: 20px
    }
     .control-dots {
         margin-top: 50px !important;
    }
     h6 {
         color: inherit;
         font-family: inherit;
         font-weight: 500;
         line-height: 1.2;
         font-size: 1rem
    }
     .carousel .slider-wrapper {
         height: 150px
    }
     body.dark .carousel .control-dots .dot {
         background-color: orange;
    }
     body.light p.slider-p {
         color: #fff;
         font-size: 11px;
         margin: 0 20px 0 10px
    }
     .carousel {
         border-radius: 10px
    }
     body.light .carousel .control-dots .dot {
         background-color: blue;
    }
     .control-dots .selected {
         background: #0f0f00;
         height: 3px !important;
         width: 10px !important;
    }
     .carousel .control-dots .dot {
         height: 3px;
         width: 3px;
         border-radius: 2px;
         box-shadow: none
    }
     .top-bar {
         height: 50px;
         display: flex;
         flex-direction: row;
         justify-content: flex-start;
         direction: rtl;
         text-align: right;
         font-family: cursive;
         align-items: center;
         vertical-align: middle;
         padding: 0 10px 0 10px;
    }
     body.light .xs-login-card {
         border: 1px solid var(--gray-1);
         border-radius: 15px;
    }
     .xs-login-card .right-login-col {
         text-align: right;
         width: 80%;
    }
     .badg-dark-signup {
         border-radius: 20px;
         display: flex;
         margin-top: 10px;
         width: 140px;
         height: 37px;
    }
     body.light .badg-dark-signup {
         background-color: #000;
         color: #fff
    }
     body.dark .badg-dark-signup {
         background-color: var(--orange);
    }
     .badg-light-green {
         background-color: #58e2c7;
         border-radius: 20px;
         color: #000 !important;
         display: flex;
         margin-top: 10px;
         width: 140px;
         height: 37px;
    }
     .xs-login-card {
         margin: 0px 10px 20px 10px !important;
         padding: 13px 13px !important;
         height: 152px;
    }
     html {
         font-family: -apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif;
    }
     body {
         padding-top: 50px;
         text-align: right;
         font-family: -apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif;
    }
     body.dark {
         background-color: #000518;
         color: #fff;
         overflow: auto;
         width: auto;
         overflow-y: auto;
         overflow-x: hidden !important
    }
     .card-body{
         padding: 15px
    }
     .top-row {
         position: fixed;
         top: 0;
         left: 0;
         right: 0
    }
     body.light .top-row {
         border-bottom: 1px solid var(--gray-1);
         background-color: #fff;
         z-index: 2
    }
     body.dark .top-row {
         border-bottom: 1px solid var(--gray-10);
         background-color: #000518;
         z-index: 2
    }
     body.dark .top-bar svg {
         color: var(--gray-1);
    }
     body.dark .top-bar .icon-round {
         color: var(--gray-1);
    }
     .top-bar .icon-round {
         height: 32px;
         width: 32px;
         margin: auto 5px auto 5px;
         align-self: center;
         align-content: center;
         cursor: pointer;
    }
     .top-bar .topbar-input {
         width: calc(100% - 130px);
         margin: 0 15px 0 15px;
    }
     body.dark .top-bar .topbar-input input {
         border: 1px solid #3a475d;
    }
     body.light .top-bar .topbar-input input {
         border: 1px solid var(--gray-1);
    }
     .market-bottom{
         display: none
    }
     .crypto-tr p{
         font-size: 12px;
         vertical-align: middle;
         align-items: center;
    }
     .crypto-td-1 p.slug{
         vertical-align: middle;
         margin-right: 20px;
         margin-top: 10px;
         font-weight: bold;
    }
     .crypto-td-6{
         display: none !important
    }
     .crypto-td-5{
         width: 300px !important
    }
     .input-group {
         height: 50px;
         border-color: #495057 !important;
    }
     body.light .input-group .btn {
         text-align: center;
         font-size: 18px;
         height: 43px;
         background-color: #e7e7e7;
         width: 200px;
         border-color: #ced4da !important;
         border-left: none !important;
         border-top-left-radius: 0px !important;
         border-bottom-left-radius: 0px !important;
         border-top-right-radius: 3px !important;
         border-bottom-right-radius: 3px !important;
         height: 50px;
    }
     body.light .input-custome-button {
         border: 1px solid #ced4da !important;
         border-left-width: 2px !important;
    }
     body.light .input-group input {
         text-align: center;
         font-size: 18px;
         height: 50px;
         border-color:#495057 !important;
    }
     body.light .input-group {
         height: 50px;
         border-color:#495057 !important;
    }
     body.dark .input-group.rtl .btn {
         width: 200px;
         border:1px solid #495057 !important;
         border-left: none !important;
         border-top-left-radius: 0px !important;
         border-bottom-left-radius: 0px !important;
         border-top-right-radius: 3px !important;
         border-bottom-right-radius: 3px !important;
         height: 50px;
         background-color: #000519
    }
     body.light .input-group .btn {
         width: 200px;
         border:1px solid #000 !important;
         border-left: none !important;
         border-top-left-radius: 0px !important;
         border-bottom-left-radius: 0px !important;
         border-top-right-radius: 3px !important;
         border-bottom-right-radius: 3px !important;
         height: 50px;
         background-color: #e7e7e7
    }
     #home04{
         display: none !important
    }
     .slider-wrapper{
         height: 300px
    }
     body.dark .serach-magnifier {
         position: relative;
         display: block;
         top: -42px;
         right: 1px;
         margin-left: auto;
         padding: 10px 0px 12px 8px;
         width: 100px;
         color: white;
    }
     body.light .serach-magnifier {
         position: relative;
         display: block;
         top: -42px;
         right: 1px;
         margin-left: auto;
         padding: 10px 0px 12px 8px;
         background-color: #e7e7e7;
         border: 1px solid #e7e7e7;
         border-radius: 3px;
         width: 100px;
         color: 000;
    }
     input.crypto-serach{
         height: 43px !important;
         border-radius: 35px
    }
     .header-sec-01 {
         display: flex;
    }
     .header-text-2 {
         padding-right: 20px;
         font-size: 22px;
         font-weight: bold;
         margin:50px auto !important;
         text-align: center;
    }
     .header-text-3 {
         padding-right: 20px;
         font-size: 18px;
         font-weight: bold;
         margin:50px auto !important;
         text-align: center;
    }
     .main-buttun{
         width: 300px
    }
     p{
         font-size: 12px
    }
     span{
         font-size: 12px
    }
     a{
         font-size: 12px
    }
     h6{
         font-size: 12px
    }
     body.dark .serach-magnifier {
         position: relative;
         display: block;
         top: -42px;
         right: 1px;
         margin-left: auto;
         padding: 10px 0px 12px 8px;
         width: 100px;
         color: white;
    }
     body.light .serach-magnifier {
         position: relative;
         display: block;
         top: -42px;
         right: 1px;
         margin-left: auto;
         padding: 10px 0px 12px 8px;
         background-color: #e7e7e7;
         border: 1px solid #e7e7e7;
         border-radius: 3px;
         width: 100px;
         color: 000;
    }
     body.dark .xs-screen {
         border: 1px solid #362d2d !important;
         border-radius: 10px;
    }
     .tx-modal .modal-content{
         height: 100% !important;
         overflow: auto;
    }
     .modal-dialog.tx-modal{
         width: 50%;
         left: 0;
         height: 100%;
         position: fixed;
         top: 0;
         margin-top: 0 !important
    }
}
 @media (min-width: 1200px) {
   form .form-button{
     width: 300px;
     height: 40px;
     margin-top: 30px;

   }
body.dark .dropdown-item:hover{
  background-color: #000
}
     .card-header-cr{
    }
     input{
         font-size: 14px !important
    }
     body.dark .text-success *{
         color: var(--mint)
    }
     body.dark .text-success {
         color: var(--mint)
    }
     .react-switch-handle{
    }
     body.dark .card-header-cr{
         border-right: 4px solid var(--mint);
         text-align: right;
         direction: rtl;
         padding: 0 20px;
         margin-bottom: 20px
    }
     body.light .card-header-cr{
         border-right: 4px solid var(--blue);
         text-align: right;
         direction: rtl;
         padding: 0 20px;
         margin-bottom: 20px
    }
     body.dark .top-market-list-card{
         border : 1px solid #e7e7e730;
         height: 400px;
         border-radius: 25px;
         padding: 20px;
    }
     body.light .top-market-list-card h5{
    }
     body.light .top-market-list-card{
         border : 1px solid #e7e7e7;
         height: 400px;
         border-radius: 25px;
         padding: 20px;
    }
     .tx-modal .modal-content{
         height: 100% !important;
    }
     .modal-dialog.tx-modal{
         left: 0;
         height: 100%;
         position: fixed;
         top: 0;
         margin-top: 0 !important
    }
     .market-list-modal{
         height: 600px;
         overflow: auto;
    }
     .tx-modal .modal-header .icon-close{
         margin-left: auto;
    }
     .tx-modal .modal-header{
         justify-content: flex-end;
         align-items: center;
         vertical-align: middle;
    }
     body.light input.market-search-input{
         height: 30px;
         border-radius: 16px;
         padding-left: 20px;
         border: 1px solid #8884d850 !important;
    }
     body.dark input.market-search-input{
         border: 1px solid #747474e3 !important;
         height: 30px;
         border-radius: 16px;
         padding-left: 20px;
    }
     body.dark input.market-search-input:focus-visible{
         border: 1px solid var(--mint) !important
    }
     body.dark input.market-search-input{
         border: 1px solid #747474e3 !important;
         height: 30px;
         border-radius: 16px;
         padding-left: 20px;
    }
     a,p,span,h6{
         font-size: 20px
    }
     body.dark .xs-screen {
         border: 1px solid #362d2d !important;
         border-radius: 10px;
    }
     .col-left .o-l-c{
         margin: 0 1px 0 1px;
    }
     body.dark .col-left{
         background-color: #2b2e39;
    }
     body.dark .o-l-c{
         background-color: #141722
    }
     body.light .col-left{
         background-color: #e7e7e7;
    }
     body.light .o-l-c{
         background-color: #fff
    }
     .xs-text-center{
         margin-top: 8px
    }
     p{
         font-size: 12px
    }
     span{
         font-size: 12px
    }
     a{
         font-size: 12px
    }
     h6{
         font-size: 12px
    }
     body.dark .market-layer input.market-input {
         background-color: #181b26;
         margin: 10px 0 10px 0;
         border: 1px solid 393939;
         height: 36px;
         font-size: 11px;
         text-align: right;
         direction: rtl;
         padding: 0 10px 0 20px;
    }
     body.light .market-layer input.market-input {
         background-color: #fff;
         margin: 10px 0 10px 0;
         border: 1px solid 393939;
         height: 36px;
         font-size: 11px;
         text-align: right;
         direction: rtl;
         padding: 0 10px 0 20px
    }
     body.dark .serach-magnifier {
         position: relative;
         display: block;
         top: -42px;
         margin-left: auto;
         padding: 10px 0px 12px 8px;
         width: 100px;
         color: white;
    }
     .market-layer{
         padding-top: 4px;
         min-height: 700px;
    }
     body.light .serach-magnifier {
         position: relative;
         display: block;
         top: -42px;
         margin-left: auto;
         padding: 10px 0px 12px 8px;
         border-radius: 3px;
         width: 100px;
         color: 000;
         height: 40px;
    }
     body.dark .serach-magnifier {
         position: relative;
         display: block;
         top: -42px;
         right: 1px;
         margin-left: auto;
         padding: 10px 0px 12px 8px;
         width: 100px;
         color: #fff;
         height: 40px;
    }
     input.crypto-serach{
         height: 43px !important;
         border-radius: 35px
    }
     .input-group {
         height: 50px;
         border-color: #495057 !important;
    }
     body.light .input-group.rtl .btn {
         text-align: center;
         font-size: 18px;
         background-color: #e7e7e7;
         border-color: #ced4da !important;
         border-top-left-radius: 0px !important;
         border-bottom-left-radius: 0px !important;
         border-top-right-radius: 3px !important;
         border-bottom-right-radius: 3px !important;
         height: 50px;
         display: flex;
         justify-content: space-between;
    }
     body.dark .input-group.rtl .btn {
         width: 200px;
         border: 1px solid #495057 !important;
         border-top-left-radius: 0px !important;
         border-bottom-left-radius: 0px !important;
         border-top-right-radius: 3px !important;
         border-bottom-right-radius: 3px !important;
         height: 50px;
         background-color: #000519;
    }
     .crypto-row{
         height: 50px;
    }
     .crypto-td-7{
         display: none !important
    }
     body.dark .market-bottom {
         display: none;
    }
     body.light .market-bottom {
         display: none;
    }
     .top-navbar-md .container-fluid .landing-nav{
         margin: 0 10px 0 10px;
    }
     .top-navbar-md .container-fluid .login-button{
         margin-right: 0 auto 0 20px;
    }
     .top-navbar-md .container-fluid{
         display: flex;
         position: fixed;
         vertical-align: middle;
         align-items: center;
         flex-direction: row;
         flex: auto;
         direction: rtl;
         top:0;
         height: 55px;
         left: 0;
         right: 0;
         z-index: 22;
         font-size: 12px;
         align-items: center;
    }
     .top-navbar-md{
         padding: 0px
    }
     body.dark .top-navbar-md .container-fluid{
         background-color: #000518;
         height: 53px;
    }
     body.dark .top-navbar-md .container-fluid .e-i{
         margin-right: auto;
    }
     body.light .top-navbar-md .container-fluid .e-i{
         margin-right: auto;
    }
     .exchange-section {
         border-radius: 20px;
         margin: 50px 20px;
         padding: 50px 20px;
    }
     body.light .top-navbar-md .container-fluid svg{
    }
     body.light .top-navbar-md .container-fluid{
         background-color: #fdfbfb;
         justify-content: space-between;
         height: 54px;
    }

     body.dark .main-wrapper {
         margin-right: 260px;
         padding: 50px 10px 70px 100px;
         font-size: 13px
    }
     body.light .main-wrapper {
         margin-right: 260px;
         padding: 50px 10px 70px 100px;
    }
     input.form-control {
         ] height: 43px;
    }
     .top-navbar-md .container-fluid .landing-nav{
         margin: 0 10px 0 10px;
    }
     .top-navbar-md .container-fluid .login-button{
         margin-right: 0 auto 0 20px;
    }
     .top-row {
         display: none !important
    }
     .top-navbar-md{
         display: flex;
         z-index: 11
    }
     body.dark .logo-container-home {
         background: url('./images/logo.png');
         background-repeat: no-repeat no-repeat;
         background-size: contain;
         width: 230px;
         height: 50px;
         margin-top: 8px;
         display: block;
         vertical-align: center;
    }
     body.light .logo-container-home {
         background: url('./images/logo-dark.png');
         background-repeat: no-repeat no-repeat;
         background-size: contain;
         width: 230px;
         height: 50px;
         margin-top: 8px;
         display: block;
         vertical-align: center;
    }
     .market-layer .btn-light {
         width: 100%;
         height: 40px;
    }
     .market-layer .btn-block {
         width: 100% !important
    }
     .market-layer .col-lg-3 {
         padding: 0 10 0 0;
         margin-right: 0
    }
     #bid {
         height: 100%;
         overflow-y: auto;
         display: flex;
         flex-direction: column !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
         line-height: 24px;
         background-color: #fff
    }
     body.light .bids {
         height: 245px;
         overflow-y: auto;
         display: flex;
         flex-direction: column !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
         padding: 5px;
         background-color: #fff
    }
     body.dark .bids {
         height: 300px;
         overflow-y: auto;
         display: flex;
         flex-direction: column !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
         padding: 5px;
    }
     #ask-bid {
         height: 700px
    }
     body.light .asks {
         height: 300px;
         overflow-y: auto;
         display: flex;
         flex-direction: column-reverse !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
         background-color: #fff
    }
     body.dark .asks p,span{
         font-size: 11px !important
    }
     body.dark .bids p,span{
         font-size: 11px !important
    }
     body.light .asks p,span{
         font-size: 11px !important
    }
     body.light .bids p,span{
         font-size: 11px !important
    }
     body.dark .asks {
         height: 300px;
         overflow-y: auto;
         display: flex;
         flex-direction: column-reverse !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
    }
     #ask {
         height: 100%;
         overflow-y: auto;
         display: flex;
         flex-direction: column !important;
         overflow: auto;
         overflow-x: hidden;
         overflow-y: scroll;
         font-size: 11px;
         line-height: 24px
    }
     .market-layer .col-xl-3 {
         padding: 0 10 0 0;
    }
     body.dark .market-layer {
         background-color: #181b26;
    }
     body.light .market-layer {
         background-color: #fff;
    }
     body.light .mc-header-2 {
         background-color: #fff;
    }
     .mc-header-2 {
         height: 28px;
         padding: 5px;
         display: flex;
         flex-direction: row-reverse;
         padding-bottom: 5px;
         margin-bottom: 1px
    }
     body.light .place-order {
         margin: 1px;
         background-color: #fff !important;
         height: 430px
    }
     body.light .market-container {
         background-color: #e7e7e7;
    }
     body.light .o-l-c {
         margin: 3px;
         background-color: #fff
    }
     body.light .mc-nav {
         background-color: #fff;
         color: #000
    }
     body.dark .market-sidebar {
         background-color: #161924;
         color: #fff;
         height: 100%;
         width: 250px;
         position: fixed;
         top: 55;
         right: 0;
         z-index: 11;
    }
     body.light .market-sidebar {
         background-color: #161924;
         color: #fff;
         height: 100%;
         width: 250px;
         position: fixed;
         top: 55;
         right: 0;
         z-index: 11;
    }
     .market-layer .col-lg-9 {
         padding: 5px;
         margin: 0 0px 0 0px
    }
     .market-layer .col-xl-9 {
         padding: 0 0px 0 5px !important;
    }
     .no-gutter {
         padding: 0 !important
    }
     body.dark .table thead th {
         vertical-align: bottom;
         border-bottom: 1px solid #9292921f
    }
     body.light .table thead th {
         vertical-align: bottom;
         border-bottom: 1px solid #9292921f
    }
     body.dark .category-badge {
         font-size: 10px;
         width: 60px;
         margin: 0 10px 0 10px;
         cursor: pointer;
         display: table-cell;
         text-align: center;
         padding: 5px 5px 5px 5px;
         border: 1px solid #9292921f;
         border-radius: 5px;
         background-color: #13161f;
    }
     body.dark .active-category-badge {
         font-size: 10px;
         width: 60px;
         margin: 0 10px 0 10px;
         cursor: pointer;
         display: table-cell;
         text-align: center;
         padding: 5px 5px 5px 5px;
         border: 1px solid #9292921f;
         border-radius: 5px;
         color: #fff;
         background-color: var(--mint);
    }
     body.light .category-badge {
         font-size: 10px;
         width: 60px;
         margin: 0 10px 0 10px;
         cursor: pointer;
         display: table-cell;
         text-align: center;
         padding: 5px 5px 5px 5px;
         border: 1px solid #9292921f;
         border-radius: 5px;
         background-color: #009cff;
         color: #fff;
    }
     body.light .active-category-badge {
         font-size: 10px;
         width: 60px;
         margin: 0 10px 0 10px;
         cursor: pointer;
         display: table-cell;
         text-align: center;
         padding: 5px 5px 5px 5px;
         border: 1px solid #9292921f;
         border-radius: 5px;
         color: #fff;
         background-color: #000518;
    }
     body.dark .form-text {
         color: #d0d4dc;
         font-size: 10px;
    }
     body.light .form-text {
         color: #000;
         font-size: 10px;
    }
     .switch-side-btn.sell .btntextsell {
         position: absolute;
         right: 61% !important;
         margin-top: 5px;
         z-index: 1099;
         color: #fff
    }
     .market-layer .btn-danger {
         background-color: var(--neon);
         border: 1px solid var(--neon);
    }
     .market-layer .btn-success {
         background-color: var(--mint);
         border: 1px solid var(--mint);
    }
     .switch-side-btn.buy .btntextbuy {
         position: absolute;
         right: 32% !important;
         margin-top: 5px;
         z-index: 1099;
         color: #fff
    }
     .switch-side-btn.sell::after {
         content: '';
         width: 60%;
         position: fixed;
         height: 35px;
         border-radius: 30px;
         background-color: var(--neon);
         left: 26px;
         text-align: center;
         border: 1px solid var(--neon);
         margin-top: -1px
    }
     .switch-side-btn.buy::after {
         content: '';
         width: 60%;
         position: fixed;
         height: 35px;
         border-radius: 30px;
         background-color: var(--mint);
         right: 26px;
         text-align: center;
         border: 1px solid var(--mint);
         margin-top: -1px
    }
     .switch-side-btn {
         margin: 10px 20px 10px 20px;
         height: 35px;
         border-radius: 30px;
         background-color: #3a3333;
         border: 1px solid #75757520;
    }
     .orderform-collapse .closebtn {
         position: fixed;
         left: 15px;
         top: 15px
    }
     body.dark .market-layer input.market-input {
         background-color: #181b26;
         margin: 10px 0 10px 0;
         border: 1px solid 393939;
         height: 36px;
         font-size: 11px;
         text-align: right;
         direction: rtl;
         padding: 0 10px 0 20px
    }
     body.light .market-layer input.market-input {
         background-color: #fff;
         margin: 10px 0 10px 0;
         border: 1px solid 393939;
         height: 36px;
         font-size: 11px;
         text-align: right;
         direction: rtl;
         padding: 0 10px 0 20px
    }
     .market-input-symbol {
         background-color: #fff0;
         color: #757575 !important;
         cursor: pointer;
         display: block;
         font-size: 14px;
         font-size: 10px;
         height: 17px;
         left: 12px;
         margin-top: -39px;
         padding: 3px;
         position: absolute;
         right: auto;
         text-align: right;
         z-index: 2;
    }
     body.dark .orderform-collapse {
         background-color: #181b26;
         position: fixed;
         bottom: 56px;
         width: 100%;
         padding: 10px 5px 10px 5px;
         color: gray;
         min-height: 500px
    }
     body.light .orderform-collapse {
         background-color: #fff;
         position: fixed;
         bottom: 56px;
         width: 500px;
         padding: 10px 5px 10px 5px;
         color: #000;
         min-height: 500px
    }
     body.dark .toright-collapse {
         background-color: #181b26;
         position: fixed;
         bottom: 56px;
         width: 500px;
         padding: 10px 5px 50px 5px;
         color: gray;
         height: 100%;
         overflow: auto;
         z-index: 8;
    }
     body.dark .toright-collapse .sticky-top{
         background-color: #181b26;
         padding-bottom: 50px;
         top:-10px
    }
     body.light .toright-collapse .sticky-top{
         background-color: #fff;
         padding-bottom: 50px;
         top:-10px
    }
     body.light .toright-collapse {
         background-color: #fff;
         position: fixed;
         bottom: 56px;
         width: 100%;
         padding: 10px 5px 10px 5px;
         color: gray;
         height: 100%;
         overflow: auto;
         z-index: 8;
         padding: 60px 0 50px 0
    }
     .toright-collapse-row .symbol-row {
         min-width: 100px !important;
         font-size: 11px;
         text-align: left;
    }
     body.dark .symbol-row.slug {
         min-width: 100px !important;
         font-size: 12px;
         text-align: left;
         font-weight: bold;
         color: #fff
    }
     body.light .symbol-row.slug {
         min-width: 100px !important;
         font-size: 12px;
         text-align: left;
         font-weight: bold;
         color: #000
    }
     .symbol-row.icon {
         min-width: 50px !important;
         font-size: 12px;
         text-align: left;
         font-weight: bold;
         color: #fff
    }
     .toright-collapse-row {
         justify-content: space-between;
    }
     .toright-collapse input {
         text-align: left !important;
         direction: ltr !important;
         font-size: 14px !important;
         margin: 10px !important
    }
     .toright-collapse-row.header {
         justify-content: space-between;
         border-bottom: 1px solid #232731;
         margin: 20px 0 20px 50px;
         padding-bottom: 10px
    }
     .order-form-row:last-child {
         margin-right: auto;
    }
     .order-form-row p{
         font-size: 13px !important
    }
     .order-form-row {
         direction: rtl;
         display: flex;
         justify-content: flex-start;
         padding: 10px;
         vertical-align: middle;
         align-items: center;
    }
     .market-bottom .btn {
         border-radius: 25px;
         height: 40px;
         margin-top: 10px
    }
     .asks:first-child .text-success {
         color: #06bc8d;
         vertical-align: middle;
         margin: auto auto 0 0;
         align-self: center;
         display: block;
         padding: 5px
    }
     .asks:first-child p {
         vertical-align: middle;
         align-self: center;
         display: block;
         padding: 5px
    }
     body.dark .mc-header-3 {
         height: 32px;
         text-align: center;
         background-color: #2b2e39;
         padding: 1px 0 1px 0;
         vertical-align: middle;
    }
     .input-custome-button{
         height: 50px
    }
     body.dark .mc-header-3 p {
         margin: 1px;
         height: 28px
    }
     body.dark .mc-header-3 div{
         background-color: #161924;
         direction: ltr;
         text-align: left;
         padding: 0 20px 0 10px
    }
     body.light .mc-header-3 {
         height: 32px;
         text-align: center;
         background-color: #e7e7e7;
         padding: 1px 0 1px 0;
         margin-left: 2px
    }
     body.light .mc-header-3 p {
         margin: 1px;
         padding: 5px 0 5px 6px;
         background-color: #fff;
    }
     .bids .text-danger {
         color: #f56754;
         vertical-align: middle;
         margin: auto auto 0 0;
         align-self: center;
         display: block;
         padding: 5px
    }
     .bids p {
         vertical-align: middle;
         align-self: center;
         display: block;
         padding: 5px
    }
     .asks:first-child {
         padding: 10px;
         display: flex;
         justify-content: space-between;
         vertical-align: middle;
    }
     body.dark .modal-dialog.sidebar .modal-content {
         background-color: #000518;
         color: #fff
    }
     body.dark .modal-dialog.sidebar {
         height: 100%;
         min-width: 250px !important;
         position: fixed;
         top: 34;
         right: 0;
         z-index: 3;
         background-color: #000518
    }
     body.light .modal-dialog.sidebar .modal-content {
         background-color: #e7e7e7;
         color: #000
    }
     body.light .modal-dialog.sidebar {
         height: 100%;
         min-width: 250px !important;
         position: fixed;
         top: 0;
         right: 0;
         z-index: 3;
         background-color: #e7e7e7
    }
     .ps-sidebar-root {
         border: none !important
    }
     .mc-nav {
         display: flex;
         height: 50px;
         position: fixed;
         top: 0;
         height: 53px;
         width: 100%;
         background-color: #0d0f14;
         z-index: 5;
         padding: 0 10px 0 10px;
         vertical-align: middle;
         align-items: center;
         color: #fff;
         font-size: 13px;
         min-width: max-content;
         border-bottom: 1px solid #e7e7e730
    }
     .d-grid {
         display: grid !important;
    }
     .mc-nav .icon-medium {
         display: block;
         height: 24px !important;
         margin-top: 6px;
         position: absolute;
         width: 24px !important;
    }
     .mc-nav .slug-header {
         margin: 5px 30px auto 50px;
         vertical-align: middle;
    }
     .mc-nav {
         align-items: center;
         .market-search {
             border-radius: 10px;
             font-size: 16px;
             height: 38px;
             padding-left: 10px;
             right: 0;
             width: 230px;
        }
    }
     .deposit-btn {
         border: 1px solid #28b0df;
         border-radius: 30px;
         display: flex;
         margin: auto 0 0 auto;
         padding: 0 10px;
    }
     .login-button {
         border-radius: 30px;
         display: flex;
         margin: auto 0 0 0;
         padding: 0 10px;
    }
     .mc-nav .mc-nav-item {
         display: flex;
    }
     .mc-nav-xs {
         display: none;
    }
     .chart-xl {
         height: 300px
    }
     .main-auth {
         padding-bottom: 100px
    }
     .main-auth .btn {
         height: 50px
    }
     body.dark ::marker {
         color: var(--orange);
    }
     body.dark {
         input:-webkit-autofill, input:-webkit-autofill:active, input:-webkit-autofill:focus, input:-webkit-autofill:hover {
             -webkit-text-fill-color: #fff;
             -webkit-background-clip: text;
             box-shadow: inset 0 0 20px 20px #23232329;
        }
    }
     body.light {
         input:-webkit-autofill, input:-webkit-autofill:active, input:-webkit-autofill:focus, input:-webkit-autofill:hover {
             -webkit-text-fill-color: #000518;
             -webkit-background-clip: text;
             box-shadow: inset 0 0 20px 20px #fff;
        }
    }
     body.dark input {
         background-color: #000518;
         color: #fff;
         border: 1px solid #232731;
         border-radius: 3px
    }
     body.light input {
         background-color: #fff;
         color: #000;
         width: 100%;
         border: 1px solid var(--gray-1);
         border-radius: 3px
    }
     .form-label {
         margin-bottom: 10px;
         margin-right: 10px;
         display: list-item;
    }
     .signup-icon {
         padding: 0 20px 0 20px;
    }
     .xs-navbar .container-fluid {
         display: flex;
         flex-direction: row;
         float: right;
         justify-content: space-between;
         font-size: 10px
    }
     .xs-navbar {
         background-color: #000518;
         bottom: 0px !important;
         color: #fff;
         position: fixed !important;
         width: 100%;
         z-index: 50;
         padding: 10px;
    }
     body.dark .coin-list-row, body.light .coin-list-row {
         background-color: #1c1c39
    }
     body.light .coin-list-row, body.light .coin-list-row {
         background-color: #e5e5ff
    }
     body.light .coin-list-row {
         border-radius: 2px;
         cursor: pointer;
         display: block;
         display: flex;
         font-size: 12px;
         height: 55px;
         justify-content: space-between;
         margin-bottom: 8px;
         vertical-align: middle;
         align-items: center;
    }
     body.dark .coin-list-row {
         border-radius: 2px;
         cursor: pointer;
         display: block;
         display: flex;
         font-size: 12px;
         height: 55px;
         justify-content: space-between;
         margin-bottom: 8px;
         vertical-align: middle;
         align-items: center;
    }
     body.light .scrollHider {
         background-color: #e7e7e7;
         padding: 1px 0 0 0;
         margin: 1px 0 0 0;
    }
     body.dark .scrollHider {
         background-color: #2b2e39;
    }
     .scrollHider {
         align-items: center;
         background-color: inherit;
         display: flex !important;
         width: 100%;
         overflow-x: auto;
         overflow-y: hidden;
    }
     body.light .menuBox {
         background-color: #fff;
         margin: 1px;
         overflow-y: hidden;
    }
     body.dark .menuBox {
         background-color: #151823;
         margin: 1px;
         overflow-y: hidden;
    }
     .menuBox {
         direction: rtl !important;
         display: flex !important;
         white-space: nowrap;
         vertical-align: middle;
         align-items: center;
         flex-direction: row;
         width: 100%;
         background-color :#181b26;
         overflow-y: hidden;
    }
     body.dark .menuBox * {
         padding: 10px 20px 9px 20px;
         color: #848484;
         font-size: 11px;
         text-align: center;
    }
     body.dark .menuBox .activetab-m {
         color: #fff;
         font-size: 11px;
         font-weight: 600;
         text-align: center;
    }
     body.light .menuBox * {
         padding: 10px 20px 9px 20px;
         color: #848484;
         font-size: 11px;
         text-align: center;
    }
     body.light .menuBox .activetab-m {
         color: #000;
         font-size: 11px;
         font-weight: 600;
         text-align: center;
    }
     .menuBox * {
         display: inline-block;
         min-width: max-content;
         max-width: 100px;
    }
     .slider-slide5-bg {
         content: '';
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0;
         left: 0;
         z-index: 1;
         background-image: url('./images/sbg05.png');
         background-size: cover;
         background-repeat: no-repeat no-repeat;
         background-position: top;
         opacity: 1
    }
     .slider-slide3-bg {
         content: '';
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0;
         left: 0;
         z-index: 1;
         background-image: url('./images/sbg04.png');
         background-size: cover;
         background-repeat: no-repeat no-repeat;
    }
     .slider-slide4-bg {
         content: '';
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0;
         left: 0;
         z-index: 1;
         background: url('./images/sbg05.png');
         background-size: cover;
         background-repeat: no-repeat no-repeat;
         background-position: center;
         opacity: 1
    }
     .slider-slide1 {
         opacity: 1;
         width: calc(100%);
         height: 550px;
         z-index: 2;
         position: inherit;
         font-family: shabnam !important
    }
     .slider-slide4 {
         opacity: 1;
         width: calc(100%);
         height: 550px;
         z-index: 2;
         position: inherit;
         font-family: shabnam !important
    }
     .slider-slide3 {
         opacity: 1;
         width: calc(100%);
         z-index: 2;
         position: inherit;
         font-family: shabnam !important
    }
     .slider-slide2 {
         opacity: 1;
         width: calc(100%);
         height: 550px;
         z-index: 2;
         position: inherit;
         font-family: shabnam !important
    }
     .slide span {
         font-size: 9px;
         display: inline-block;
         margin-top: 20px
    }
     .control-dots {
         margin-top: 50px !important;
    }
     h6 {
         color: inherit;
         font-family: inherit;
         font-weight: 500;
         line-height: 1.2;
         font-size: 1rem
    }
     .carousel .slider-wrapper {
         height: 150px
    }
     body.dark .carousel .control-dots .dot {
         background-color: orange;
    }
     p.slider-p{
         margin: 0 30px 30px 10px
    }
     body.light p.slider-p {
         color: #fff;
         font-size: 11px;
    }
     .carousel {
         border-radius: 10px
    }
     body.light .carousel .control-dots .dot {
         background-color: blue;
    }
     .control-dots .selected {
         background: #0f0f00;
         height: 3px !important;
         width: 10px !important;
    }
     .carousel .control-dots .dot {
         height: 3px;
         width: 3px;
         border-radius: 2px;
         box-shadow: none
    }
     .top-bar {
         height: 50px;
         display: flex;
         flex-direction: row;
         justify-content: flex-start;
         direction: rtl;
         text-align: right;
         font-family: cursive;
         align-items: center;
         vertical-align: middle;
         padding: 0 10px 0 10px;
    }
     body.light .xs-login-card {
         border: 1px solid var(--gray-1);
         border-radius: 15px;
    }
     .xs-login-card .right-login-col {
         text-align: right;
         width: 80%;
    }
     .badg-dark-signup {
         border-radius: 20px;
         display: flex;
         margin-top: 10px;
         width: 140px;
         height: 37px;
    }
     body.light .badg-dark-signup {
         background-color: #000;
         color: #fff
    }
     body.dark .badg-dark-signup {
         background-color: var(--orange);
    }
     .badg-light-green {
         background-color: #58e2c7;
         border-radius: 20px;
         color: #000 !important;
         display: flex;
         margin-top: 10px;
         width: 140px;
         height: 37px;
    }
     .xs-login-card {
         margin: 0px 10px 20px 10px !important;
         padding: 13px 13px !important;
         height: 152px;
    }
     html {
         font-family: -apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif;
    }
     body {
         padding-top: 50px;
         text-align: right;
         font-family: -apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif;
    }
     body.dark {
         background-color: #000518;
         color: #fff;
         overflow: auto;
         width: auto;
         overflow-y: auto;
         overflow-x: hidden !important
    }
     .top-row {
         position: fixed;
         top: 0;
         left: 0;
         right: 0
    }
     body.light .top-row {
         border-bottom: 1px solid var(--gray-1);
         background-color: #fff;
         z-index: 2
    }
     body.dark .top-row {
         border-bottom: 1px solid var(--gray-10);
         background-color: #000518;
         z-index: 2
    }
     body.dark .top-bar svg {
         color: var(--gray-1);
    }
     body.dark .top-bar .icon-round {
         color: var(--gray-1);
    }
     .top-bar .icon-round {
         height: 32px;
         width: 32px;
         margin: auto 5px auto 5px;
         align-self: center;
         align-content: center;
         cursor: pointer;
    }
     .top-bar .topbar-input {
         width: calc(100% - 130px);
         margin: 0 15px 0 15px;
    }
     body.dark .top-bar .topbar-input input {
         border: 1px solid #3a475d;
    }
     body.light .top-bar .topbar-input input {
         border: 1px solid var(--gray-1);
    }
     .market-bottom{
         display: none
    }
     .crypto-tr p{
         font-size: 12px;
         vertical-align: middle;
         align-items: center;
    }
     .crypto-td-1 p.slug{
         vertical-align: middle;
         margin-right: 20px;
         margin-top: 10px;
         font-weight: bold;
    }
     .crypto-td-6{
         display: none !important
    }
     .crypto-td-5{
         width: 300px !important
    }
}
