body.dark{
	width: auto;
	overflow-y: auto;
	overflow-x: hidden !important;
	background-color: #000518 !important;
	background: url('./images/hero-glow.svg');
	background-repeat: no-repeat;
	background-size: auto;
	background-position: top;
	color: #fff;
	height: 100%
}
body.light{
	width: auto;
	overflow-y: auto;
	overflow-x: hidden !important;
	background-color: #fff !important;
	color: #000;
	height: 100%;
  color: #000
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

.market-avatar {
	width: 32px;
	height: 32px;
	border: 1px solid #28b0df;
	border-radius: 50%;
	align-content: center;
	align-items: center;
	text-align: center;
	margin: auto 0 0 0;
}

.deposit-btn {
	height: 32px;
	border: 1px solid #28b0df;
	border-radius: 30px;
	align-content: center;
	margin: auto 0 0 auto;
	display: flex;
	align-items: center;
	text-align: center;
	padding: 0 10px 0 10px;
}

.dropdown-menu.sidebar-market {
	color: #fafafa !important;
	position: fixed !important;
	top: 53px;
	background-color: #000009 !important;
	border: 1px solid #201739 !important;
	border-top: none;
	z-index: 1999 !important;
	height: 100%;
	width: 300px;
	overflow: auto;
	overflow-x: hidden;
	overflow-y: auto;
	right: -7px;
	bottom: 0 !important;
	left: auto;
}

.topbar-input {
	width: calc(100% - 130px) !important
}

.topbar-input input {
	width: 100%
}

@media (min-width:1200px) {


    	body.dark .main-auth.hue {
    		background: url('./images/glow-2.svg');
    		background-position: right !important;
    		background-repeat: no-repeat;
    		background-size: contain;
    	}

    	.ordering-row {
    		min-width: 500px;
    		overflow: auto;
    		overflow-x: auto;
    		overflow-y: hidden;
    		display: flex;
    		height: 40px;
    		vertical-align: middle;
    		direction: rtl;
    		padding: 10px 0
    	}




    	.market-bottom {
    		display: none;

    	}

    	.mc-nav-xs {
    		display: none !important
    	}

    	.market-layer-bottom {
    		height: 40%;
    		width: 100%;
    		border: 1px solid yellow
    	}

    	.nav-x-scrool {
    		width: 1200px;
    		display: flex;
    		overflow: auto;
    		overflow-x: auto;
    		overflow-y: hidden;
    	}

    	.place-order {
    		height: 300px
    	}

    	.mc-1 {
    		padding: 0 !important;

    	}

    	.chart-xl {
    		padding: 0 !important;
    		height: 350px !important
    	}

    	.mc-2 {
    		padding: 0 !important;

    	}

    	.mc-3 {
    		padding: 0 !important;

    	}





    	.col-xl-d {
    		width: 300px !important
    	}

    	.mc-nav .icon-medium {
    		width: 24px !important;
    		height: 24px !important;
    		margin-top: 2px;
    		display: block;
    		position: absolute;
				z-index: 2
    	}



    	.current-price {
    		padding: 5px;
    		height: 40px;
    		display: flex !important;
    		justify-content: space-between;
    	}

    	.o-l-c {
    		max-height: calc(100% - 70px);
    		min-height: inherit;

    	}


    	.assets-list {
    		min-height: 50% !important;
    		height: 400px;
    		overflow: auto;
    		overflow-x: hidden;
    		overflow-y: scroll;
    	}



    	.text-gary {
    		color: darkslategray !important
    	}

    	span.text-dgary {
    		color: darkslategray !important
    	}

    	.mc-nav .mc-nav-item {
    		display: flex;
    	}

    	body.dark .market-collapse {
    		direction: rtl;
    		position: fixed;
    		min-width: 600px;
    		max-width: max-content;
    		height: 400px;
    		padding: 10px;
    		border-radius: 10px;
    		top: 120px;
    		margin-left: 155px;
    		background-color: #000518;
    		z-index: 1020;
    		border: 1px solid #e7e7e730;
    		overflow: auto;
    		overflow-y: auto;
    		overflow-x: hidden;
    	}

    	.toright-collapse {
    		position: fixed;
    		min-width: 600px;
    		max-width: max-content;
    		height: calc(100 - 60px);
    		padding: 10px;
    		border-radius: 10px;
    		top: 60 !important;
    		bottom: 0 !important;
    		right: auto;
    		left: 0;
    		background-color: #000;
    		z-index: 1020;
    		border: 1px solid #fafafa20;
    		overflow: auto;
    		overflow-y: auto;
    		overflow-x: hidden;
    	}

    	.toleft-collapse {
    		position: fixed;
    		width: 600px;
    		height: calc(100 - 60px);
    		padding: 10px;
    		border-radius: 10px;
    		top: 60 !important;
    		bottom: 0 !important;
    		left: auto;
    		right: 600;
    		background-color: #000;
    		z-index: 1020;
    		border: 1px solid #fafafa20;
    		overflow: auto;
    		overflow-y: auto;
    		overflow-x: hidden;
    	}

    	.symbol-row {
    		width: 150px
    	}

    	.w-10 {
    		width: 10%
    	}

    	.current-price-item {
    		width: 120px;
    		font-family: fangsong !important
    	}

    	.ordering-row span {
    		margin-left: 15px;
    		display: block;
    		margin-top: auto;
    		margin-bottom: auto;
    		cursor: pointer;
    		color: #e7e7e7
    	}

    	.float-label .icon-info {
    		background-color: none !important
    	}

    	.float-label {
    		width: fit-content !important;
    		position: relative;
				position: relative;
	        bottom: -19px;
	        right: 13px;
	        background-color: #060e27;
	        color: #00f0f050 !important;
	        padding: 0 10px;
    	}


    	.xs-md-coin-name {
    		margin-right: 10px;
    		font-size: 17px !important;
    		margin-top: 10px
    	}

    	.ordelist::-webkit-scrollbar {
    		width: 0.2em;
    	}

    	.ordelist::-webkit-scrollbar-track {
    		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    	}

    	.ordering-row::-webkit-scrollbar {
    		width: 0.2em;
    	}

    	.ordering-row::-webkit-scrollbar-track {
    		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    	}

    	#cryptolist table tbody td {
    		border-bottom: thin solid rgba(0, 0, 0, .12);
    		height: 70px
    	}

    	#cryptolist table tbody td .btn-group {
    		margin-top: 13px;
    		height: 40px !important
    	}

    	#cryptolist table tbody tr:hover {
    		-webkit-box-shadow: 0 0 15px rgba(0, 0, 0, .15);
    		box-shadow: 0 0 15px rgba(0, 0, 0, .15);
    		background-color: #182c47 transparent !important;
    		cursor: pointer;
    		height: 70px;
    	}

    	.ordelist::-webkit-scrollbar-thumb {
    		background-color: #0f203e;
    		outline: 1px solid #0f203e;
    	}

    	.form-search {
    		border-color: #00ffff87;
    		border-radius: 35px;
    		font-size: 16px;

    	}
/*
    	body.dark .text-success {
    		color: #36ff64 !important
    	}

    	body.light .text-success {
    		color: #006f19 !important
    	} */

    	.modal-header {
    		display: flex;
    		align-items: flex-start;
    		justify-content: space-between;
    		padding: 1rem;
    		border-bottom: 1px solid #1b407047;
    		border-top-left-radius: 0.3rem;
    		border-top-right-radius: 0.3rem;
    	}

    	.close {
    		float: right;
    		font-size: 1.5rem;
    		font-weight: 700;
    		line-height: 1;
    		color: #fff;
    		opacity: .5;
    	}

    	.dropzone {
    		border: 1px dotted #fafafa20;
    		padding: 10px 5px;
    		border-radius: 3px;
    		margin-left: 3px;
    		margin-right: 3px;
    	}

    	.alert-info p {
    		color: #0c5460 !important
    	}

    	.wallet-card {
    		background-color: #1b407047
    	}

    	.menuBox a {
    		color: #fff;
    		margin-left: 30px;
    		display: inline-block;
    		text-align: center;
    	}

    	.sticky-top-container {
    		position: sticky;
    		top: 60px;
    		z-index: 5;
    		background-color: #0f203e;
    	}




    	.text-muted {
    		color: #f0f0f0 !important
    	}

    	.dropdown-divider {
    		height: 0;
    		margin: 0.5rem 0;
    		overflow: hidden;
    		border-top: 1px solid #42556b;
    	}

    	.exchange-box {
    		text-align: center;
    		margin: auto;
    		width: 70px;
    		height: 70px;
    		background-color: #162b46;
    		border: 1px solid #42556b;
    		border-radius: 50%;
    		padding: 10px 10px;
    	}

    }


    body.dark {
    	a {
    		color: #fff
    	}

    	.md-row {
    		display: none;
    	}

    	.card-info-cr .price-c {
    		position: fixed;
    		height: 40px;
    		width: 100%;
    		background-color: #0b1a2f;
    		bottom: 2px;
    		left: 0;
    		right: 0;
    		border-radius: 10px;
    	}

    	#dashboard-chart-section {
    		border-radius: 10px
    	}

    	.navbar-dark {
    		background-color: #0f203e !important
    	}

    	input:-webkit-autofill,
    	input:-webkit-autofill:hover,
    	input:-webkit-autofill:focus,
    	input:-webkit-autofill:active {
    		-webkit-background-clip: text;
    		-webkit-text-fill-color: #ffffff;
    		box-shadow: inset 0 0 20px 20px #23232329;
    	}

    	.xs-md-coin-name {
    		color: #fff;
    	}

    	.btn-light {
    		background-color: #162b4680;
    		border: 1px solid #162b4600;
    	}


    	.d-box-f {
    		background-color: #002c6533;
    		color: #fff;
    		border: 1px solid #42556b;
    		border-radius: 10px;
    		height: 100px;
    		overflow: hidden;
    		overflow-y: hidden;
    		overflow-x: hidden;
    	}

    	.exchange-section {

    		border-radius: 20px;
    		margin: 50px 20px;
    		padding: 50px 20px;
    	}

    	#dashboard-chart {
    		margin: 30px 0 60px 0;
    		padding: 10px;
    		border: 1px solid #495057 !important;
    		border-radius: 10px;
    		background-color: #162b46
    	}

    	.xs-slide {
    		height: max-content;

    		padding: 10px 20px 10px 20px;
    	}

    	.icon-round {}

    	.main-logo-light {
    		display: none;

    	}

    	.main-logo-dark {
    		height: 90px;
    		width: 90px;
    		display: block !important
    	}

    	#swal2-title p {
    		color: #fff !important;
    		font-size: 18px !important
    	}

    	.btn-grad {
    		background-image: linear-gradient(to right, #F0F2F0 0%, #000C40 51%, #F0F2F0 100%) !important;
    		margin: 10px;
    		padding: 15px 45px;
    		text-align: center;
    		text-transform: uppercase;
    		transition: 0.5s;
    		background-size: 200% auto;
    		color: white;
    		box-shadow: 0 0 2px #eee;
    		border-radius: 10px;
    		display: block;
    	}

    	.btn-grad:hover {
    		background-position: right center;
    		color: #fff;
    		text-decoration: none;
    	}

    	.main-auth {
    		min-height: 100%;

    		.btn {
    			font-size: 16px;
    			height: 44px;
    			color: #fff !important;
    		}
    	}

    	.main-auth .logo-container-home {
    		background: url('./images/logo.png') !important;
    		background-repeat: no-repeat no-repeat !important;
    		background-size: contain !important;
    		width: 250px !important;
    		height: 50px;
    		margin-top: 8px;
    		display: block;
    		vertical-align: center;

    	}

    	.swal2-popup {
    		background-color: #0b1a2f !important;
    		color: #a4a4a4 !important;
    	}

    	.swal2-container {
    		background: rgb(0 0 0 / 58%) !important;
    		backdrop-filter: blur(5px) !important;
    	}

    	.market-input-dark input {}



    	.market .border-bottom {
    		border: 1px solid #162b46 !important
    	}

    	.market .border-left {
    		border: 1px solid #162b46 !important
    	}

    	.market .border-right {
    		border: 1px solid #162b46 !important
    	}

    	.fav-market-icon {
    		color: #fafafa !important;
    	}

    	.fav-icon-active {
    		color: #fff
    	}

    	.market-input-dark input:focus {
    		border: 1px solid #a4a4a490 !important;
    		outline: 0;
    		box-shadow: none;
    		background-color: #0b1a2f !important;
    		color: #fff !important;
    	}

    	.address {
    		color: #000 !important;
    	}

    	.phone {
    		color: #000 !important;
    	}

    	.footer-logo-container {
    		background-image: url('./images/logo.png');
    		background-size: cover;
    		background-repeat: no-repeat;
    		width: 250px;
    		height: 50px;
    		display: block;
    		margin: auto;
    	}
			body.dark .sidebar .ps-menu-label{
				color: #fff !important
			}
    	#home04 {
    		background-image: url('./images/04bg3.jpg');
    		background-size: cover;
    		height: 400px;
    		padding-top: 100px !important;
    		width: calc(100%) !important;
    		background-repeat: no-repeat;
    		background-position: center;
    		filter: brightness(-2px);
    	}

    	.glaas-card {
    		width: 300px;
    		height: 200px;
    		position: absolute;
    		top: 90px;
    		left: 200px;
    		border-radius: 20px;
    		border: 1px solid #fff;
    		z-index: 2;
    		color: #fff
    	}

    	.coin-list-row {
    		background-color: #4194ff0a !important
    	}

    	.list-header {
    		background-color: #091227 !important
    	}

    	.card-row-left {
    		height: 187px;
    		width: 350px;
    		padding: 100px 0 50px 0;
    		margin: 50px;
    		padding-top: 40px;
    		border-radius: 10px;

    	}

    	.card-row-right {
    		height: 187px;
    		width: 350px;
    		padding: 100px 0 50px 0;
    		margin: 50px;
    		padding-top: 40px;
    		border-radius: 10px;

    	}

    	.card-row-center {
    		height: 187px;
    		width: 350px;
    		padding: 100px 0 50px 0;
    		margin: 50px;
    		padding-top: 40px;
    		border-radius: 10px;

    	}

    	.glaas-card-bg-2 {
    		content: "";
    		backdrop-filter: blur(6px);
    		background-color: #162b46ab !important;

    		-webkit-backdrop-filter: blur(5px);
    		position: absolute;
    		top: 0;
    		left: 0;
    		height: 100%;
    		width: 100%;
    		border-radius: 20px;
    		z-index: 2
    	}

    	.glaas-card-bg {
    		content: "";
    		backdrop-filter: blur(6px);

    		-webkit-backdrop-filter: blur(5px);
    		position: absolute;
    		top: 0;
    		left: 0;
    		height: 100%;
    		width: 100%;
    		border-radius: 20px;
    		z-index: 2
    	}

    	.glaas-card {

    		width: 300px;
    		height: 200px;
    		position: absolute;
    		top: 90px;
    		left: 200px;
    		border-radius: 20px;
    		border: 1px solid #ffe7e730;
    		z-index: 3;
    		color: #0f203e
    	}

    	.glaas-card-2 {
    		width: 200px;
    		height: 200px;
    		position: absolute;
    		top: 250px;
    		left: 50px;
    		border-radius: 20px;
    		border: 1px solid #ffe7e730;
    		z-index: 2;
    		color: #0f203e;
    		padding: 10px
    	}

    	.security-card-logo {
    		width: 80px;
    		height: 80px;
    		background: url('./images/securepay.svg');
    		margin: auto;
    	}

    	.border-light-xl {
    		border: 1px solid #495057 !important;
    		border-radius: 5px;
    	}

    	.piggy-card-logo {
    		width: 80px;
    		height: 80px;
    		background: url('./images/piggybank.svg');
    		margin: auto;
    	}

    	.wallet-card-logo {
    		width: 80px;
    		height: 80px;
    		background: url('./images/digital-wallet.svg');
    		margin: auto;
    	}

    	.xl-menu {
    		background-color: #0f203e !important;

    	}

    	.ticker #tradingview_widget_wrapper::after {
    		content: '';
    		width: 100%;
    		height: 35px;
    		background-color: #0b1a2f !important;
    		position: absolute;
    		bottom: 0px;
    	}

    	.input-inherit input {
    		background-color: #162b4600 !important;
    		color: #fff !important;
    		border: 1px solid #495057 !important
    	}

    	.selected-text {
    		color: #fff !important
    	}

    	.icon-refresh {
    		color: #58e2c7 !important
    	}

    	.icon-reload {
    		color: #58e2c7 !important
    	}

    	.icon-info {
    		color: #58e2c7 !important
    	}

    	.text-medium a {
    		color: #58e2c7 !important
    	}


    	.card {
    		border: 1px solid #23595e90 !important;
				border-radius: 10px
    	}

    	.card-header {
    		background-color: #0b1a2f !important;
    		border-bottom: 1px solid #23595e90 !important;
    		border-top-right-radius: 10px !important;
    		border-top-left-radius: 10px !important
    	}

    	.dropdown-item:hover {
    		color: #fff !important;
    		background-color: #0f203e50;
    	}

    	.dropdown-menu {
    		color: #fafafa !important;
    		background-color: #000518 !important;
    		border: 1px solid #201739 !important;
    		border-top: none;
    		z-index: 11;
    		max-height: 300px;
    		overflow: auto;
    		overflow-x: hidden;
    		overflow-y: auto;
				width: 100%
    	}

    	.dropdown-menu.custome-dropdown-menu-left {
    		color: #fafafa !important;
    		background-color: #000009 !important;
    		border: 1px solid #201739 !important;
    		border-top: none;
    		z-index: 11;
    		height: 600px;
    		width: 400px;
    		overflow: auto;
    		overflow-x: hidden;
    		overflow-y: auto;
    		right: 0;
    		left: auto;
    	}

    	.dropdown-menu.sidebar-market {
    		color: #fafafa !important;
    		position: fixed;
    		top: 53px;
    		background-color: #000009 !important;
    		border: 1px solid #201739 !important;
    		border-top: none;
    		z-index: 11;
    		height: 100%;
    		width: 300px;
    		overflow: auto;
    		overflow-x: hidden;
    		overflow-y: auto;
    		right: -7px;
    		bottom: 0 !important;
    		left: auto;
    	}

    	.-webkit-thead {
    		background-color: #272b2f !important;
    		border-bottom: 1px solid #495057 !important
    	}

    	.exchange-input-symbol {
    		position: absolute;
    		left: 12px;
    		top: 16px;
    		color: #fff
    	}

    	.border {
    		border: 1px solid #42556b;
    	}

    	.search input:focus {
    		background-color: inherit !important;
    		color: #fff !important
    	}

    	.xl-navbar {
    		background-color: #ffffff00
    	}

    	.card-body {
    		background-color: #0f203e69 !important;
    		border-bottom-left-radius: 10px;
    		border-bottom-right-radius: 10px;
    		border-top-right-radius: 0 !important;
    		border-top-left-radius: 0 !important;
    	}

    	#footer {
    		background-color: #fff !important;
    		color: #0b1a2f !important;
    		padding-left: 10px;
    		padding-right: 10px;

    	}

    	#footer a {
    		text-decoration: none;
    		color: #0b1a2f !important;
    		font-weight: 100;
    	}

    	#footer a :hover {
    		color: #0f0f00 !important;
    		font-weight: 400;
    	}

    	.logo-container-dark {
    		background-image: url('./images/logo-dark.png') !important;
    		background-repeat: no-repeat no-repeat !important;
    		margin: auto;
    		width: 250px;
    		height: 50px;
    		display: block;
    	}

    	.logo-box {
    		margin: auto;
    		width: 200px
    	}



    	.card-info {
    		bottom: 0;
    		width: 250px;
    		top: auto;
    		background-color: #6059595e;
    		height: 250px;
    		border-radius: 10px;
    		margin-bottom: 25px;
    	}

    	.text-main {
    		color: #ffc107
    	}

    	.card-info-2 {
    		bottom: 0;
    		position: absolute;
    		width: 250px;
    		top: auto;
    		height: 200px;
    		border-radius: 10px;
    		margin-bottom: 25px;
    	}

    	.card-info-3 {
    		position: absolute;
    		width: 250px;
    		top: -20px;
    		left: 0;
    		background-color: #6059595e;
    		height: 350px;
    		border-radius: 10px;
    	}

    	.card-info-4 {
    		position: absolute;
    		width: 250px;
    		top: 0;
    		background-color: #6059595e;
    		height: 250px;
    		border-radius: 10px;
    	}

    	.footer a {
    		color: #0b1a2f !important
    	}

    	.footer {
    		background-color: #fafafa !important
    	}

    	.btn-secondary {
    		border: none;
    	}

    	.modal-footer {
    		display: flex;
    		align-items: center;
    		justify-content: flex-end;
    		padding: 1rem;
    		border-top: 1px solid #495057;
    	}

    	.card-footer {
    		display: flex;
    		align-items: center;
    		justify-content: flex-end;
    		padding: 1rem;
    		border-top: 1px solid #495057;
    	}

    	.card-info-cr {}

    	.alert-light {
    		color: #f5f5f5 !important;
    		background-color: #0b1a2f;

    	}

    	.coin-list-light-header {
    		background-color: #00142c !important;
    		color: #fff !important;
    		border: none !important
    	}

    	.coin-list-light {
    		background-color: #00142c !important;
    		color: #fff !important;
    		border: none !important
    	}

    	.alert-primary {
    		color: #fafafa !important;
    		background-color: #25364a;
    		border-color: #42556b
    	}

    	.custome-dropdown-item {
    		color: #fafafa !important;
    		border-color: #42556b;
    		padding: 10px
    	}

    	.logo-container-xs-2 {
    		background: url('./images/logo.png');
    		background-repeat: no-repeat;
    		height: 50px;
    		width: 250px;
    		background-size: cover;
    	}

    	.custome-dropdown-item a {
    		color: #fafafa !important;
    	}

    	.market .text-success {
    		color: #00bc8d !important;
    	}

    	.market .btn-success {
    		background-color: #00bc8d !important;
    	}

    	.market .btn-danger {
    		background-color: #f76654 !important;
    	}

    	.market .text-danger {
    		color: #f76654 !important;
    	}

    	.shadow {
    		box-shadow: -5px -5px 9px rgb(22 43 70 / 68%), 5px 5px 4px rgb(16 38 70);

    	}

    	.panel-navbar {
    		box-shadow: -1px 1px 20px 0px #725a5a50 !important;
    	}

    	.tx-modal {
    		background-color: #0b1a30;
    	}

    	.user-UL {
    		background-color: #58e2c7 !important;
    		color: #000 !important
    	}

    	.icon-shuffle {
    		color: #58e2c7 !important
    	}

    	.market .text-muted {
    		color: #58e2c7 !important
    	}




    	.alert-success {
    		color: #fafafa !important;
    		background-color: #25364a;
    		border-color: #42556b
    	}

    	#usdt-darks {
    		border: 1px solid #42556b !important;
    		border-radius: 5px
    	}

    	#home01 p {
    		color: orange !important;

    	}

    	.container {}

    	.bg-ocean {
    		background-color: #0f203e !important;
    	}

    	.bg-darkblue {
    		background-color: #0b1a2f !important;
    	}



    	.tab-inline {
    		width: 100px;
    		padding-bottom: 10px;
    		color: #fff;
    		text-align: center;
    	}



    	#react-select-3-listbox {
    		background-color: #0b1a2f !important;
    		border-color: #42556b
    	}

    	#react-select-7-listbox {
    		background-color: #0b1a2f !important;
    		border-color: #42556b
    	}

    	#react-select-3-listbox {
    		background-color: #0b1a2f !important;
    		border-color: #42556b
    	}

    	#react-select-3-listbox {
    		background-color: #0b1a2f !important;
    		border-color: #42556b
    	}

    	#react-select-3-listbox {
    		background-color: #0b1a2f !important;
    		border-color: #42556b
    	}

    	.input-custome-button {
    		background-color: #000518 !important;
    		border:1px solid #42556b !important;
    		border-top-right-radius: 5px;
    		border-bottom-right-radius: 5px;
    		border-top-left-radius: 0px !important;
    		border-bottom-left-radius: 0px !important;
    	}

    	input.swap-input {
    		color: #fff !important;
    		background-color: #0b1a2f00 !important;
    		padding-left: 40px;
    		border-top-left-radius: 5px;
    		border-bottom-left-radius: 5px
    	}

    	.bg-dark {
    		background-color: #000
    	}

    	.btn {
    		color: #fff !important
    	}

    	.card-transactions {
    		padding: 15px;
    		color: #fff !important;
    		border-radius: 20px;
    		height: 300px;
    		overflow: scroll;
    		background-color: #162b46;
    		border: 1px solid #42556b;
    	}

    	.col-fit-right {
    		padding: 10px;
    		color: #fff !important;
    		border-radius: 10px;
    		height: 200px;
    		overflow: scroll;
    		background-color: #1b407047;
    	}

    	tr {
    		border-color: #ffffff00
    	}

    	.col-fit-left {
    		padding: 10px;
    		color: #fff !important;
    		border-radius: 10px;
    		height: 200px;
    		overflow: scroll;
    		background-color: #1b407047;

    	}

    	.card-transactions .text-muted {
    		color: #58e2c7 !important
    	}

    	.icon-screen-smartphone {
    		color: #58e2c7 !important
    	}

    	.top-navbar span {
    		color: inherit !important
    	}

    	.btn-warning {
    		color: #000 !important
    	}

    	#quoteamount {
    		color: #fff !important
    	}

    	input {
    		color: #fff !important;
    		background-color: #ffffff00 !important;
    	}

    	#a2 {
    		color: #fff !important;
    		background-color: #162b46 !important;
    		border: 1px solid #0f203e !important
    	}

    	.light-search input {
    		color: #fff !important;
    		background-color: #162b46 !important;
    		border: 1px solid #495057 !important;
    		padding-left: 10px
    	}

    	.market input {
    		color: #fff !important;
    		background-color: #162b4600 !important;
    		border: 1px solid #495057 !important;
    		border-radius: 3px;

    	}

    	.b-small {
    		color: #fff !important;
    		background-color: darkslategray !important;
    		border: 1px solid #495057 !important
    	}

    	.exchange input {
    		color: #fff !important;
    		background-color: #162b46 !important;
    		border: 1px solid #495057 !important;
    		border-right: none !important
    	}

    	.main-layer input {
    		color: #fff !important;
    		background-color: #162b46 !important;
    		border: 1px solid #495057 !important
    	}


    	.bg-light {
    		background-color: #0058ca00 !important;
    	}

    	.tr {
    		background-color: #162b46 !important;
    	}

    	.wallet-card-irt {
    		background-color: #162b46 !important;
    		color: #fff !important
    	}

    	.wallet-card-usdt {
    		background-color: #162b46 !important;
    		color: #fff !important
    	}

    	.wallet-card-irt-bottom {
    		background-color: #58e2c7 !important;
    		color: #162b46 !important
    	}

    	.wallet-card-usdt-bottom {
    		background-color: #58e2c7 !important;
    		color: #162b46 !important
    	}

    	.sidebar .sidebar-setting-icon {
    		display: none;
    		color: #ffc107 !important
    	}

    	.exit-box .sidebar-icon-inherit {
    		color: #000 !important;
    		margin-right: 20px
    	}

    	.exit-box {
    		display: flex;
    		background-color: #ffc107 !important;
    		color: #0f0f00 !important;
    		height: 55px;
    		border-left: 4px solid #000 !important
    	}

    	.sidebar p {
    		color: #fff !important
    	}


    	/* .btn-success.focus,
    	.btn-success:focus {
    		color: #fff;
    		background-color: #00bc8d;
    		border-color: #00bc8d !important;
    		box-shadow: none !important
    	} */

    	.button-row .active-tabs {
    		color: #58e2c7;
    		border-bottom: 2px solid #A4DE02;
    		padding-bottom: 30px
    	}

    	.xl-navbar.bg-transparent .dark-icon-round {
    		background: #0f203e !important;
    		color: #000 !important;
    		border-radius: 50%;
    		width: 30px;
    		height: 30px;
    		margin: auto 3px auto 3px;
    		align-items: center;
    		padding: 7px;
    		display: table;
    	}

    	.sidebar .sidebar-icon {
    		color: gray !important
    	}

    	.sidebar .sidebar-item-active {
    		.sidebar-item-active {
    			padding-left: 15px;
    			padding-right: 15px;
    			border-bottom: 1px solid #00020005;
    			border-bottom-width: thin;
    			border-radius: 3px;
    			border-left: 3px solid #7e96bc;
    			height: 50px !important;
    			color: #fff !important;
    			padding-top: 9px;
    			display: flex;
    			flex: auto;
    			background-color: #19253eab;
    			vertical-align: middle;
    		}

    	}


    	.table-hover tbody tr:hover {
    		color: #cbd7e4;
    		background-color: rgba(0, 0, 0, .075);
    	}

    	.sidebar .sidebar-icon {
    		color: #58e2c7 !important
    	}

    	.panel-wp {}

    	.list-header {
    		background-color: #1c1e23;
    	}

    	.input-group input {
    		border-color: #232731 !important;
    		height: 43px
    	}

    	.btn.form-control {
    		border-radius: 3px
    	}

    	input.form-control {
    		height: 43px
    	}

    	.coin-list-2 {
    		color: #fff !important
    	}

    	.border-bottom {
    		border-color: #1c1e23 !important;
    	}

    	.input-group input {
    		height: 50px
    	}



    	.input-group .input-custome-button {}

    	.market-input-dark input {
    		background-color: #0b1a2f !important
    	}

    	.light-border {
    		border: 1px sold #243244
    	}

    	.text-white-fix {
    		color: #fff !important
    	}

    	a .text-dark {
    		color: #fff !important;
    	}

    	a:hover {
    		color: inherit;
    		text-decoration: none !important
    	}


    	.bg-widget {
    		background-color: #0b1a2f !important;
    	}

    	.div {
    		background-color: #0b1a2f !important;
    	}



    	input:-webkit-input {
    		background-color: #162b4600 !important;
    		color: #fff !important
    	}

    	#auth-login input {
    		background-color: #162b4600;
    		color: #fff
    	}

    	#auth-login {
    		min-height: 100%;
    		padding-bottom: 100px;

    	}

    	input:-webkit-autofill,
    	input:-webkit-autofill:focus {
    		background-color: #ffffff00
    	}


    }
